<template>
  <div class="knowledge-graph">
    <!-- 面包屑 -->
    <div style="margin:20px 0 0 20px;">
      <MyBreadcrumb></MyBreadcrumb>
    </div>
    <div class="map-create">
      <div class="top">
        <div class="left">
          <!-- 教材封面 -->
          <div class="left-cover">
            <Upload :action="constant.URL + '/uploadfile/upload'" :data="{type: 3}" accept=".jpg,.png,.jpeg,.gif"
              :headers="{'access-token': Cookies.get(constant.tokenName),}" :on-success="coverFileSuccess"
              :show-upload-list="false">
                <img v-if="bookImg" :src="bookImg" class="left-cover-img" width="110" height="110" />
                <div class="left-cover-bj" v-else>
                  <img src="../../assets/images/new_icon/upload.png" />
                </div>
                <div slot="tip">教材尺寸：84*106,课程尺寸：200*110</div>
            </Upload>
          </div>
          <!-- 教材信息 -->
          <div class="left-info">
            <div class="left-info-l">
              <div class="left-info-l-item">
                <span>{{ mapType == "course" ? "名称" : "教材名称" }}:</span>
                <Input class="left-info-l-item-input" v-model="name"
                  :placeholder="$t('dataset_map_create_name_placeholder')" />
              </div>
              <div class="left-info-l-item" v-if="mapType != 'course'">
                <span>出版社:</span>
                <Input class="left-info-l-item-input" v-model="bookPress"
                  :placeholder="$t('dataset_map_create_name_placeholder')" />
              </div>
              <div class="left-info-l-item" v-if="mapType != 'course'">
                <span>作者:</span>
                <Input class="left-info-l-item-input" v-model="bookAuthor"
                  :placeholder="$t('dataset_map_create_name_placeholder')" />
              </div>
            </div>
            <div class="left-info-l bg">
              <div class="left-info-l-item">
                <span style="flex-shrink: 0;width:auto;">题库:</span>
                <div style="flex-shrink: 0; width: 160px;text-overflow: ellipsis; overflow: hidden;text-wrap: nowrap;" >
                  {{
                    mapinfo.category
                    ? mapinfo.category.name +
                    "(id:" +
                    mapinfo.category.id +
                    ")"
                    : ""
                  }}
                </div>

              </div>
              <div class="left-info-l-item">
                <span style="width: auto;">题库数据量:</span>{{ mapinfo.category ? mapinfo.category.data_count : "" }}
              </div>
              <div class="left-info-l-item">
                <span style="width: auto;">图谱数据量:</span>{{ mapinfo.data_count }}
              </div>
              <div class="left-info-l-item">
                <span style="width: auto;">图谱状态:</span>{{ statusObj[mapinfo.status] }}
              </div>
            </div>
          </div>
          <!--          <span class="titl">{{$t('dataset_map_create_name')}}:</span>-->
          <!--          <Input v-model="name" :placeholder="$t('dataset_map_create_name_placeholder')" />-->
          <!--          <span class="cate">关联分类:{{mapinfo.category ? mapinfo.category.name + '(id:'+mapinfo.category.id+')': ''}}</span>-->
        </div>
        <!-- 头部右侧操作按钮 -->
        <div style="display: flex">
          <div>
            <div>
              <Button type="success" class="confirm" @click="startMatch()">智能匹配</Button>
            </div>
            <div class="mt10">
              <Button type="warning" class="confirm" @click="deleteAllNode()">清空章节</Button>
            </div>
            <div class="mt10">
              <Button type="warning" class="confirm" @click="deleteAllData()">清空数据</Button>
            </div>
          </div>
          <div>
            <div>
              <Button type="primary" class="confirm" @click="leadingIn">导入图谱节点</Button>
            </div>
            <!-- <div class="mt10">
              <Button type="primary" class="confirm" @click="batchUpload">批量导入文件</Button>
            </div> -->
            <div class="mt10">
              <Button type="primary" class="confirm" @click="update">保存信息和更新统计</Button>
            </div>
            <div class="mt10">
              <Button type="primary" class="confirm" @click="drawerShow = true"
                  v-if="isSuper">切换题库</Button>
            </div>
          </div>
        </div>
      </div>
      <!-- 表格 -->
      <table class="table">
        <!-- 第一行 -->
        <tr>
          <th :colspan="2 + maxLengh">考核范围</th>
          <!-- <th style="width: 120px">考核知识点/考点</th>
          <th style="width: 120px">考核知识点/关键词</th> -->
          <th style="width: 120px">考核知识点/试题</th>
        </tr>
        <!-- 第二行 -->
        <tr>
          <th>一级节点</th>
          <th>二级节点</th>
          <th :colspan="maxLengh">节点</th>
          <!-- <th rowspan="2">考点</th>
          <th rowspan="2">关联关键词</th> -->
          <th rowspan="2">关联试题</th>
        </tr>
        <!-- 第三行 -->
        <tr>
          <th>
            <div style="height: 40px">
              <p>名称</p>
              <!-- <p>权重</p> -->
            </div>
          </th>
          <th>
            <div style="height: 40px">
              <p>名称</p>
              <!-- <p>权重</p> -->
            </div>
          </th>
          <th v-for="(i, index) in maxLengh" :key="index + 'name'">
            <div style="height: 40px">
              <p>名称</p>
            </div>
          </th>
        </tr>
        <!-- 第四行及之后   treeData每一个元素代表一行  -->
        <tr v-for="(item, index) in treeData" :key="index + 'level1'">
          <td v-for="(sItem, sIndex) in item" :key="sItem.id + sIndex"
            :rowspan="sItem.children_count == 0 ? Number(sItem.children_count) + 1 : sItem.children_count" v-if="sItem">
            <div v-if="sItem.id && sItem.id != -1">
              <div class="td-name" @click="edit(item, sIndex)">
                {{ sItem.node.name }}
                <img src="../../assets/images/new_icon/edit.png" width="12" height="14" />
              </div>
              <div class="td-op">
                <div @click="checkImg(sItem)" v-if="sItem.img">
                  <img src="../../assets/images/new_icon/image.png" width="14" height="13" />
                  <span>|</span>
                </div>
                <!-- <div @click="edit(item, sIndex)">
                  <span>权重{{ sItem.weight }}%</span>
                  <span>|</span>
                </div> -->
                <!-- <div @click="addFile(sItem, 'vedio_info', '1', index, sIndex)">
                  <span :class="sItem.vedio_info.length ? 'has' : ''">视频</span>
                  <img v-if="sItem.vedio_info.length" src="../../assets/images/new_icon/cg.png" width="12" height="12" />
                  <img v-else src="../../assets/images/new_icon/tj.png" width="12" height="12" />
                  <span>|</span>
                </div>
                <div @click="addFile(sItem, 'ppt_info', '2', index, sIndex)">
                  <span :class="sItem.ppt_info.length ? 'has' : ''">课件</span>
                  <img v-if="sItem.ppt_info.length" src="../../assets/images/new_icon/cg.png" width="12" height="12" />
                  <img v-else src="../../assets/images/new_icon/tj.png" width="12" height="12" />
                  <span>|</span>
                </div>
                <div @click="addFile(sItem, 'enchiridion_info', '3', index, sIndex)">
                  <span :class="sItem.enchiridion_info.length ? 'has' : ''">实操</span>
                  <img v-if="sItem.enchiridion_info.length" src="../../assets/images/new_icon/cg.png" width="12"
                    height="12" />
                  <img v-else src="../../assets/images/new_icon/tj.png" width="12" height="12" />
                </div> -->
                <div @click="addFiles(sItem, sIndex)">
                  <div style="margin-right: 5px;">
                    <span>视频：{{ sItem.vedio_info.length }}</span>
                  </div>
                  <div style="margin-right: 5px;">
                    <span>课件：{{ sItem.ppt_info.length }}</span>
                  </div>
                  <div style="margin-right: 5px;">
                    <span>手册：{{ sItem.enchiridion_info.length }}</span>
                  </div>
                  <div >
                    <span>实验：{{ sItem.experiment_info.length }}</span>
                  </div>
                </div>
                <div style="display: flex;flex-direction: column;margin-left: 10px;">
                  <el-button type="text" size="mini" @click="move(item, sIndex, '1')">上移↑</el-button>
                  <el-button type="text" size="mini" style="margin-left: 0;"
                    @click="move(item, sIndex, '2')">下移↓</el-button>
                </div>
              </div>

              <!--              <p>{{sItem.weight}}%</p>-->
              <!--              <div class="op-btn">-->
              <!--                <p @click="edit(item,sIndex)"><Icon type="ios-create" /></p>-->
              <!--                <p @click="addPpt(item,sIndex)">{{sItem.ppt_info.id ? '编辑ppt' : '添加ppt'}}</p>-->
              <!--                <p @click="addVideo(item,sIndex)">{{sItem.vedio_info.id ? '编辑视频' : '添加视频'}}</p>-->
              <!--                <p @click="dele(item,sIndex)"><Icon type="md-close"  /></p>-->
              <!--              </div>-->
            </div>
            <div class="add-btn" style="cursor: pointer" @click="addNode(item, sIndex - 1, index)"
              v-else-if="sItem.id == -1">
              <Icon type="md-add" class="add-btn-icon" :size="30" />
            </div>
            <div v-else></div>
          </td>
          <!--          <td v-if="index< treeData.length -1">-->
          <!--            <div class="table-label" style="cursor: pointer;align-items: center" @click="addLabel(item,1)">-->
          <!--              <span style="font-size: 16px;font-weight: bold" :style="{color:(item[item.length - 2].label_keypoints_count =='0' || !item[item.length - 2].label_keypoints_count) ? 'black' :'red'}">{{item[item.length - 2].label_keypoints_count || ''}}</span>-->
          <!--              <Icon type="md-add" class="add-label-icon" :size="30" />-->
          <!--            </div>-->

          <!--          </td>-->
          <!--          <td v-if="index< treeData.length -1">-->
          <!--            <div class="table-label" style="cursor: pointer;align-items: center" @click="addLabel(item,0)">-->
          <!--              <span style="font-size: 16px;font-weight: bold" :style="{color:(item[item.length - 2].label_keywords_count =='0' || !item[item.length - 2].label_keywords_count) ? 'black' :'red'}">{{item[item.length - 2].label_keywords_count || ''}}</span>-->
          <!--              <Icon type="md-add" class="add-label-icon" :size="30" />-->
          <!--              &lt;!&ndash;              <span class="label-item" v-for="sItem in item[item.length - 2].labels">{{sItem.name}}<Icon type="md-close" style="margin-left: 5px" @click.stop="deleLabel(sItem,item)" /></span><Icon type="md-add" class="add-label-icon" :size="30" />&ndash;&gt;-->
          <!--            </div>-->

          <!--          </td>-->
          <td v-if="index < treeData.length - 1">
            <div class="table-label" style="cursor: pointer; align-items: center" @click="addLabel(item)">
              <span style="font-size: 16px; font-weight: bold" :style="{
                color:
                  item[item.length - 2].datas_count == '0' ||
                    !item[item.length - 2].datas_count
                    ? 'black'
                    : 'red',
              }">{{ item[item.length - 2].datas_count || "" }}</span>
              <Icon type="md-add" class="add-label-icon" :size="30" />
            </div>
          </td>
        </tr>
<!--        <tr>-->
<!--          <td v-for="(item, index) in weightTotal" :key="index">-->
<!--            <div style="height: 40px">-->
<!--              <p>权重合计</p>-->
<!--              <p>{{ item }}%</p>-->
<!--            </div>-->
<!--          </td>-->
<!--        </tr>-->
      </table>

      <!-- <Modal v-model="modal" :title="type == 'add' ? $t('dataset_map_create_add') : $t('dataset_map_create_edit')">
        <div class="modal-cont">
          <div class="item">
            <span>{{ $t('dataset_map_create_name') }}：</span>
            <Input class="val" v-model="nodeName" type="textarea"
              :placeholder="$t('dataset_map_create_name_placeholder')" />
          </div>
          <div class="item">
            <span>权重：</span>
            <Input class="val" v-model="weight" type="number" placeholder="请输入权重" />
          </div>
          <div class="item">
            <span>描述：</span>
            <Input class="val" v-model="desc" type="textarea" placeholder="请输入描述" />
          </div>
          <div class="item">
            <span>图片：</span>
            <el-upload :action="constant.URL + '/uploadfile/upload'" :data="{
              type: 3
            }" accept=".jpg,.png,.jpeg,.gif" :headers="{
  'access-token': Cookies.get('liteAccessToken')
}" :on-success="uploadNodeImgSuccess" :show-file-list="false">
              <div class="upload-btn" v-if="!imgUrl">
                <Icon type="ios-add" :size="50" />
              </div>
              <img :src="imgUrl" width="120" v-else />
            </el-upload>
          </div>
          <div class="item">
            <span>排序：</span>
            <Input class="val" v-model="sort" type="text" placeholder="请输入图片" />
          </div>
        </div>

        <div slot="footer">
          <Button type="error" @click="dele" v-if="type == 'edit'">删除</Button>
          <Button @click="modal = false">{{ $t('dataset_map_create_cancel') }}</Button>
          <Button type="primary" @click="confirm">{{ $t('dataset_map_create_confirm') }}</Button>
        </div>
      </Modal> -->

      <!-- <Modal v-model="addLabelModal" :title="'添加标签(' + lastNode.name + ')'" width="1500px" :footer-hide="true"
        @on-cancel="getInfo()">
        <div class="add-data-sub-tab">
          <div :class="curAddDataSubTabId == item.id ? 'active' : ''" v-for="item in addDataSubTabList" :key="item.id"
            @click="changeAddDataTab(item.id, 'curAddDataSubTabId')">{{ item.name }}</div>
        </div>
        <div class="add-label flex_start">
          <div v-if="curAddDataSubTabId == '2'" class="list-cont">
            <Input v-model="dataKeyword" :placeholder="$t('common_search_keyword')" clearable search :enter-button="true"
              @on-search="changeDataKeyword" class="keyword-search" />
            <Table border ref="selection" :columns="unRelationDataColumns" :data="unRelationDataList"
              @on-select="selectAddData" @on-select-cancel="selectDeleData" @on-select-all="selectAddData"
              @on-select-all-cancel="selectDeleData"></Table>
            <div class="page">
              <Page :total="unRelationDataTotal" :page-size="unRelationDataPageSize" @on-change="changeUnRelationDataPage"
                @on-page-size-change="changeUnRelationDataPageSize" :current="unRelationDataPage" show-total></Page>
            </div>
            <Button type="primary" @click="confirmAddData">确定</Button>
          </div>
          <div v-else>
            <div class="list-cont">
              <div class="select-cont" v-if="curAddDataSubTabId == '1'">
                <div v-for="(item, index) in selectData" :class="curSelectPoint.id == item.id ? 'active' : ''"
                  :key="item.id + index + ''" @click="changeSelectPoint(item, '1')">
                  <p>{{ item.name }} </p>
                  <Icon v-if="item.id != -1" type="md-close" :color="curSelectPoint.id == item.id ? '#FFFFFF' : '#666666'"
                    @click.stop="nodeDelLabelData(item.id)" />
                </div>
              </div>
              <div class="select-cont" v-else>
                <div v-for="(item, index) in selectDataLable" :class="curSelectLabel.id == item.id ? 'active' : ''"
                  :key="item.id + index + ''" @click="changeSelectLabel(item, '0')">
                  <p>{{ item.name }} </p>
                  <Icon v-if="item.id != -1" type="md-close" :color="curSelectLabel.id == item.id ? '#FFFFFF' : '#666666'"
                    @click.stop="nodeDelLabelData(item.id)" />
                </div>
              </div>
            </div>
            <div class="list-cont">
              <div class="label-search">
                <Input v-model="keyword" :placeholder="$t('common_search_keyword')" clearable search :enter-button="true"
                  @on-search="changeKeyword" class="keyword-search" />
              </div>
              <div class="list">
                <div :class="item.isSelect ? 'active' : ''" v-for="item in labelList" :key="item.id"
                  @click="selectDataFn(item)">{{ item.name }}</div>
              </div>
              <div class="page">
                <Page :total="total" :page-size="pageSize" @on-change="changePage" @on-page-size-change="changePageSize"
                  :current="page" show-sizer></Page>
              </div>
            </div>
          </div>
          <div class="label-data">
            <Table border ref="selection" :loading="nodeDataLoading" :columns="nodeDataColumns" :data="nodeDataList"
              @on-select="nodeAddData" @on-select-cancel="nodeDelData" @on-select-all="nodeAddAllData"
              @on-select-all-cancel="nodeDelAllData"></Table>
            <div class="page">
              <Page :total="nodeDataTotal" :page-size="nodeDataPageSize" @on-change="changeLabelDataPage"
                @on-page-size-change="changeLabelDataPageSize" :current="nodeDataPage" show-total show-sizer
                show-elevator></Page>
            </div>
          </div>
        </div>
      </Modal> -->

      <!-- <Modal v-model="uploadModal" title="导入激活码" width="1000px">
        <p style="margin-bottom: 20px">导入文件格式(".xlsx",".xls")</p>
        <Upload :action="constant.URL + '/uploadfile/upload'" :data="{
          type: 0,
        }" accept=".xlsx,.xls" :headers="{
  'access-token': Cookies.get('liteAccessToken'),
}" :on-success="fileSuccess" :show-upload-list="false">
          <Button icon="ios-cloud-upload-outline">选择文件</Button>
          <span style="padding-left: 20px">{{
            uploadFile ? uploadFile : "未选择文件"
          }}</span>
        </Upload>
        <div slot="footer">
          <Button @click="cancelUpload">取消</Button>
          <Button type="primary" @click="confirmUpload">确定</Button>
        </div>
      </Modal> -->

      <!-- <Modal v-model="batchAddFileModal" title="批量导入文件" width="1000px">
        <div class="batch-tab">
          <div :class="curBatchTab == item.id ? 'active' : ''" v-for="item in batchTabList" :key="item.id"
            @click="changeBatchTab(item)">
            {{ item.name }}
          </div>
        </div>
        <div class="batch-file mt20">
          <Button type="text" class="mr20" @click="downTemplate">下载模版</Button>
          <Upload :action="constant.URL + '/uploadfile/upload'" :data="{
            type: 0,
          }" accept=".xls,.xlsx,.csv,.xlsm" :headers="{
  'access-token': Cookies.get('liteAccessToken'),
}" :on-success="batchFileSuccess" :show-upload-list="false">
            <Button icon="ios-cloud-upload-outline" type="primary">选择文件</Button>
            <span style="padding-left: 20px">{{
              batchFileName ? batchFileName : "未选择文件"
            }}</span>
          </Upload>
        </div>
        <div class="mt20">
          <Table :columns="batchColumns" :data="batchData"></Table>
          <div class="page">
            <MyPage :total="batchTotal" :current="batchPage" @changePage="changeBatchPage"
              @changePageSize="changeBatchPageSize"></MyPage>
          </div>
        </div>
        <div slot="footer">
          <Button @click="cancelBatchFile">取消</Button>
          <Button type="primary" @click="confirmBatch">确定</Button>
        </div>
      </Modal> -->

      <Modal v-model="checkImgModal" title="查看图片" width="800px">
        <div style="text-align: center">
          <img :src="curNodeData.img" width="700" />
        </div>
      </Modal>

      <DeleModal :status="deleModalStatus" txt="确认删除节点吗?删除后子节点一起删除" :loading="modalLoading" @cancel="cancelDele"
        @confirm="confirmDele"></DeleModal>
    </div>

    <!-- 批量导入文件抽屉 -->
    <el-drawer title="" :visible.sync="batchAddFileModal" :size="800">
      <div class="drawer-title" slot="title">
        <div class="drawer-title-text">批量导入文件</div>
        <div class="drawer-title-btns">
          <Button @click="cancelBatchFile">取消</Button>
          <Button type="primary" @click="confirmBatch">确定</Button>
        </div>
      </div>
      <div style="padding: 20px;">
        <!-- <p style="margin-bottom: 20px">导入文件格式(".xlsx",".xls")</p> -->
        <div class="batch-tab">
          <div :class="curBatchTab == item.id ? 'active' : ''" v-for="item in batchTabList" :key="item.id"
            @click="changeBatchTab(item)">
            {{ item.name }}
          </div>
        </div>
        <div class="batch-file mt20">
          <Button type="text" class="mr20" @click="downTemplate">下载模版</Button>
          <Upload :action="constant.URL + '/uploadfile/upload'" :data="{
            type: 0,
          }" accept=".xls,.xlsx,.csv,.xlsm" :headers="{
  'access-token': Cookies.get(constant.tokenName),
}" :on-success="batchFileSuccess" :show-upload-list="false">
            <Button icon="ios-cloud-upload-outline" type="primary">选择文件</Button>
            <span style="padding-left: 20px">{{
              batchFileName ? batchFileName : "未选择文件"
            }}</span>
          </Upload>
        </div>
        <div class="mt20">
          <Table :columns="batchColumns" :data="batchData"></Table>
          <div class="page">
            <MyPage :total="batchTotal" :current="batchPage" @changePage="changeBatchPage"
              @changePageSize="changeBatchPageSize"></MyPage>
          </div>
        </div>
      </div>
    </el-drawer>
    <!-- 导入激活码抽屉 -->
    <el-drawer title="导入激活码" :visible.sync="uploadModal" :size="800">
      <div class="drawer-title" slot="title">
        <div class="drawer-title-text">导入激活码</div>
        <div class="drawer-title-btns">
          <Button @click="cancelUpload" style="margin-right: 10px;">取消</Button>
          <Button type="primary" @click="confirmUpload">确定</Button>
        </div>
      </div>
      <div style="padding: 20px;">
        <p style="margin-bottom: 20px">导入文件格式(".xlsx",".xls")</p>
        <Upload :action="constant.URL + '/uploadfile/upload'" :data="{
          type: 0,
        }" accept=".xlsx,.xls" :headers="{
  'access-token': Cookies.get(constant.tokenName),
}" :on-success="fileSuccess" :show-upload-list="false">
          <Button icon="ios-cloud-upload-outline">选择文件</Button>
          <span style="padding-left: 20px">{{
            uploadFile ? uploadFile : "未选择文件"
          }}</span>
        </Upload>
      </div>
    </el-drawer>
    <!-- 切换题库抽屉 -->
    <el-drawer title="切换题库" :visible.sync="drawerShow" :size="800" direction="rtl">
      <div slot="title" class="drawer-title">
        <div class="drawer-title-text">切换题库</div>
        <div class="drawer-title-btns">
          <el-button size="small" @click="cancelSwitch()">取消</el-button>
          <el-button size="small" type="primary" :loading="switchQuestionLoading" @click="changeOk()">确定</el-button>
        </div>
      </div>
      <div style="padding: 20px">
        <el-alert title="注意：切换题库时，系统会自动备份原图谱所有内容。若需使用原图谱，请到图谱列表中查找。" type="warning" :closable="false"></el-alert>
        <!-- 查询筛选 -->
        <div class="search">
          <div class="search-item">
            <div class="name">类型:</div>
            <div class="cont">
              <div class="values">
                <div :class="questionCateForm.type == item.id ? 'active' : ''" v-for="(item, index) in categoryTypeList"
                  :key="index + 'type'" @click="searchChange(item.id, 'type')">
                  {{ item.name }}
                </div>
              </div>
            </div>
          </div>
          <div class="search-item">
            <div class="name">一级分类:</div>
            <div class="cont">
              <div class="values">
                <div :class="
                  questionCateForm.category_id == item.id ? 'active' : ''
                " v-for="item in categoryLevel1" :key="item.id + 'category'"
                  @click="searchChange(item.id, 'category_id')">
                  {{ item.name }}
                </div>
              </div>
            </div>
          </div>
          <div class="search-item" v-if="categoryLevel2.length > 1">
            <div class="name">二级分类:</div>
            <div class="cont">
              <div class="values">
                <div :class="
                  questionCateForm.category_second_id == item.id
                    ? 'active'
                    : ''
                " v-for="item in categoryLevel2" :key="item.id + 'secondCategory'"
                  @click="searchChange(item.id, 'category_second_id')">
                  {{ item.name }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <el-form label-width="180px" style="margin-top: 50px;" :model="switchQuestionBankForm">
          <el-form-item label="是否保留章节">
            <el-radio-group v-model="switchQuestionBankForm.a">
              <el-radio :label="'1'">是</el-radio>
              <el-radio :label="'0'">否</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="是否保留数据">
            <el-radio-group v-model="switchQuestionBankForm.b">
              <el-radio :label="'1'">是</el-radio>
              <el-radio :label="'0'">否</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item>
            <el-button size="small" @click="cancelSwitch()">取消</el-button>
            <el-button size="small" type="primary" :loading="switchQuestionLoading" @click="changeOk()">确定</el-button>
          </el-form-item>
        </el-form> -->
        <el-table size="small" :data="tableData" border :row-class-name="tableRowClassName" style="width: 100%">
          <el-table-column prop="id" label="序号" width="80"> </el-table-column>
          <el-table-column prop="name" label="名称"> </el-table-column>
          <el-table-column prop="data_count" label="数据条数" width="80">
          </el-table-column>
          <el-table-column prop="auto_open" label="自动开通" width="80">
            <template v-slot="{ row }">
              {{ row.auto_open === "1" ? "自动开通" : "手动开通" }}
            </template>
          </el-table-column>
          <el-table-column prop="is_free" label="免费刷题" width="80">
            <template v-slot="{ row }">
              {{ row.is_free === "1" ? "免费" : "不免费" }}
            </template>
          </el-table-column>
          <el-table-column label="操作" width="80">
            <template v-slot="{ row }">
              <el-button v-if="currentRow !== row.id" size="small" type="primary" @click="selectQB(row)">选择</el-button>
              <div v-else style="color: #2d8cf0; font-size: 18px; text-align: center">
                √
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-drawer>
    <!-- 添加标签抽屉 -->
    <el-drawer :visible.sync="addLabelsDrawerShow" :title="'添加标签(' + lastNode.name + ')'" :size="'80%'"
      @close="getInfo()">
      <div slot="title">
        <div style="font-size: 18px; color: #333333; font-weight: bold">
          {{ "添加标签(" + lastNode.name + ")" }}
        </div>
      </div>
      <div style="padding: 20px">
        <!-- 切换关联按钮 -->
        <div class="add-data-sub-tab">
          <div :class="curAddDataSubTabId == item.id ? 'active' : ''" v-for="item in addDataSubTabList" :key="item.id"
            @click="changeAddDataTab(item.id, 'curAddDataSubTabId')">
            {{ item.name }}
          </div>
        </div>
        <div class="add-label flex_start">
          <div v-if="curAddDataSubTabId == '2'" class="list-cont">
            <Input v-model="dataKeyword" :placeholder="$t('common_search_keyword')" clearable search :enter-button="true"
              @on-search="changeDataKeyword" class="keyword-search" />
            <Table border ref="selection" :columns="unRelationDataColumns" :data="unRelationDataList"
              @on-select="selectAddData" @on-select-cancel="selectDeleData" @on-select-all="selectAddData"
              @on-select-all-cancel="selectDeleData"></Table>
            <div class="page">
              <Page :total="unRelationDataTotal" :page-size="unRelationDataPageSize" @on-change="changeUnRelationDataPage"
                @on-page-size-change="changeUnRelationDataPageSize" :current="unRelationDataPage" show-total></Page>
            </div>
            <Button type="primary" @click="confirmAddData">确定</Button>
          </div>
          <div v-else>
            <div class="list-cont">
              <div class="select-cont" v-if="curAddDataSubTabId == '1'">
                <div v-for="(item, index) in selectData" :class="curSelectPoint.id == item.id ? 'active' : ''"
                  :key="item.id + index + ''" @click="changeSelectPoint(item, '1')">
                  <p>{{ item.name }}</p>
                  <Icon v-if="item.id != -1" type="md-close" :color="
                    curSelectPoint.id == item.id ? '#FFFFFF' : '#666666'
                  " @click.stop="nodeDelLabelData(item.id)" />
                </div>
              </div>
              <div class="select-cont" v-else>
                <div v-for="(item, index) in selectDataLable" :class="curSelectLabel.id == item.id ? 'active' : ''"
                  :key="item.id + index + ''" @click="changeSelectLabel(item, '0')">
                  <p>{{ item.name }}</p>
                  <Icon v-if="item.id != -1" type="md-close" :color="
                    curSelectLabel.id == item.id ? '#FFFFFF' : '#666666'
                  " @click.stop="nodeDelLabelData(item.id)" />
                </div>
              </div>
            </div>
            <div class="list-cont">
              <div class="label-search">
                <Input v-model="keyword" :placeholder="$t('common_search_keyword')" clearable search :enter-button="true"
                  @on-search="changeKeyword" class="keyword-search" />
              </div>
              <div class="list">
                <div :class="item.isSelect ? 'active' : ''" v-for="item in labelList" :key="item.id"
                  @click="selectDataFn(item)">
                  {{ item.name }}
                </div>
              </div>
              <div class="page">
                <Page :total="total" :page-size="pageSize" @on-change="changePage" @on-page-size-change="changePageSize"
                  :current="page" show-sizer></Page>
              </div>
            </div>
          </div>
          <div class="label-data">
            <Table border ref="selection" :loading="nodeDataLoading" :columns="nodeDataColumns" :data="nodeDataList"
              @on-select="nodeAddData" @on-select-cancel="nodeDelData" @on-select-all="nodeAddAllData"
              @on-select-all-cancel="nodeDelAllData"></Table>
            <div class="page">
              <Page :total="nodeDataTotal" :page-size="nodeDataPageSize" @on-change="changeLabelDataPage"
                @on-page-size-change="changeLabelDataPageSize" :current="nodeDataPage" show-total show-sizer
                show-elevator></Page>
            </div>
          </div>
        </div>
      </div>
    </el-drawer>
    <!-- 添加节点抽屉 -->
    <el-drawer :visible.sync="modal" :size="800">
      <div slot="title" class="drawer-title">
        <div class="drawer-title-text">{{ type == 'add' ? $t('dataset_map_create_add') : $t('dataset_map_create_edit') }}
        </div>
        <div class="drawer-title-btns">
          <Button type="error" @click="dele" v-if="type == 'edit'" style="margin-right: 10px;">删除</Button>
          <Button @click="modal = false" style="margin-right: 10px;">{{
            $t("dataset_map_create_cancel")
          }}</Button>
          <Button type="primary" @click="confirm">{{
            $t("dataset_map_create_confirm")
          }}</Button>
        </div>
      </div>
      <div class="modal-cont" style="padding: 20px;">
        <div class="item">
          <span>{{ $t("dataset_map_create_name") }}：</span>
          <Input class="val" v-model="nodeName" type="textarea"
            :placeholder="$t('dataset_map_create_name_placeholder')" />
        </div>
        <div class="item">
          <span>权重：</span>
          <Input class="val" v-model="weight" type="number" placeholder="请输入权重" />
        </div>
        <div class="item">
          <span>描述：</span>
          <Input class="val" v-model="desc" type="textarea" placeholder="请输入描述" />
        </div>
        <div class="item">
          <span>图片：</span>
          <el-upload :action="constant.URL + '/uploadfile/upload'" :data="{
            type: 3,
          }" accept=".jpg,.png,.jpeg,.gif" :headers="{
  'access-token': Cookies.get(constant.tokenName),
}" :on-success="uploadNodeImgSuccess" :show-file-list="false">
            <div class="upload-btn" v-if="!imgUrl">
              <Icon type="ios-add" :size="50" />
            </div>
            <img :src="imgUrl" width="120" v-else />
          </el-upload>
        </div>
        <!-- <div class="item">
          <span>排序：</span>
          <Input class="val" v-model="sort" type="text" placeholder="请输入图片" />
        </div> -->
      </div>
    </el-drawer>
    <!-- 添加文件抽屉 -->
    <el-drawer :visible.sync="addFileModal" size="80%" @close="cancelFileModal">
      <div class="drawer-title" slot="title">
        <div class="drawer-title-text">{{ type === "edit" ? drawerTitle : "添加节点" }}</div>
      </div>
      <div style="padding:20px;">

        <div class="tabbar">
          <div :class="tabActive == 0 ? 'tabActive' : ''" @click="changeTab('0')">基本信息</div>
          <div :class="tabStyle('1')" @click="changeTab('1')">已绑定资源</div>
          <div :class="tabStyle('2')" @click="changeTab('2')">全部资源</div>
          <div :class="tabStyle('3')" @click="changeTab('3')">已绑定实验</div>
          <div :class="tabStyle('4')" @click="changeTab('4')">全部实验</div>
        </div>
        <el-alert v-show="tabActive === '1' || tabActive ==='2' " type="warning" style="margin-bottom: 20px;">注意：仅展示当前题库下的资源</el-alert>
        <div class="uploadTypeTab" v-show="tabActive === '1' || tabActive ==='2' ">
          <div class="uploadType-item" :class="addFileType==item.id?'uploadType-item-active':''" v-for="item in libTypeList" :key="item.id" @click="changeFileType('',item.id)">{{ item.name }}</div>
        </div>
        <div class="uploadTypeTab" v-show="tabActive ==='3' || tabActive==='4'">
          <div class="uploadType-item" :class="addExperType===item.id?'uploadType-item-active':''" v-for="item in experTypeList" :key="item.id" @click="changeExperType(item.id)">{{ item.name }}</div>
        </div>
        <template v-if="tabActive === '0'">
          <div class="modal-cont" style="padding: 20px;">
            <div class="item">
              <span>节点ID：</span>
              <Input disabled class="val" v-model="nodeId" type="text" placeholder="请输入节点ID" />
            </div>
            <div class="item">
              <span>{{ $t("dataset_map_create_name") }}：</span>
              <Input class="val" v-model="nodeName" type="textarea"
                :placeholder="$t('dataset_map_create_name_placeholder')" />
            </div>
            <!-- <div class="item">
              <span>权重：</span>
              <Input class="val" v-model="weight" type="number" placeholder="请输入权重" />
            </div> -->
            <div class="item">
              <span>描述：</span>
              <Input class="val" v-model="desc" type="textarea" placeholder="请输入描述" />
            </div>
            <div class="item">
              <span>图片：</span>
              <el-upload :action="constant.URL + '/uploadfile/upload'" :data="{
                type: 3,
              }" accept=".jpg,.png,.jpeg,.gif" :headers="{
  'access-token': Cookies.get(constant.tokenName),
}" :on-success="uploadNodeImgSuccess" :show-file-list="false">
                <div class="upload-btn" v-if="!imgUrl">
                  <Icon type="ios-add" :size="50" />
                </div>
                <img :src="imgUrl" width="120" v-else />
              </el-upload>
            </div>
          </div>
          <div class="drawer-title" style="justify-content: flex-end;">
            <div class="drawer-title-btns">
              <Button type="error" @click="dele" v-show="type == 'edit'" style="margin-right: 10px;">删除</Button>
              <!-- <Button @click="modal = false" style="margin-right: 10px;">{{
                $t("dataset_map_create_cancel")
              }}</Button> -->
              <Button v-show="type == 'add'" type="primary" @click="confirm()"
                style="margin-right: 10px;">保存并创建下一个</Button>
              <Button type="primary" @click="confirm(true)">保存并关闭</Button>
            </div>
          </div>
        </template>
        <template v-else-if="tabActive === '1'">
          <!-- <el-button type="primary" style="margin-bottom: 20px;" size="small"
            @click="addLibAndBinding()">上传并绑定题库资源</el-button> -->
          <div>
            <el-table :data="addFileList" border size="small">
              <el-table-column prop="id" label="序号" width="80">
              </el-table-column>
              <el-table-column prop="name" label="名称">
              </el-table-column>
              <el-table-column prop="type" label="类型" width="200">
                <template v-slot="{ row }">
                  {{ libTypeList.find(item => item.id === row.type)?libTypeList.find(item => item.id === row.type).name:'' }}
                </template>
              </el-table-column>
              <el-table-column prop="link" label="链接">
              </el-table-column>
              <el-table-column prop="address" label="操作" width="170">
                <template v-slot="{ row }">
                  <el-button size="mini" type="primary" @click="previewLib(row)">预览</el-button>
                  <el-button size="mini" type="warning" @click="deleFile(row)"
                    :loading="unbindingLoading && row.id === unbindingId">解绑</el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>

          <!-- <Button type="error" @click="deleFile" v-if="addFileList.length">解绑</Button> -->

          <!-- <div class="mt20" v-if="addFileList.length && addFileList[curFileIndex].path">
            <video v-if="addFileType == 1" ref="video" id="videoPlayer" :src="addFileList[curFileIndex].path"
              class="video-js vjs-default-skin" controls width="100%" controlslist="nodownload"></video>
            <div v-if="addFileType == 3" style="font-size: 14px; margin-bottom: 10px">
              实操跳转链接：{{ addFileList[curFileIndex].link }}
              <a :href="addFileList[curFileIndex].link" target="_blank"
                style="color: #2d8cf0; text-decoration: underline">点击跳转</a>
            </div>
            <embed v-if="addFileType != 1" id="iframe" ref="myIframe" :src="addFileList[curFileIndex].path" width="100%"
              height="600px" type="application/pdf" />
          </div> -->
        </template>
        <template v-else-if="tabActive === '2'">
          <div class="input-wrap">
            <Input v-model="libKeyword" placeholder="模糊搜索" clearable search
              class="transform" :enter-button="true"
              @on-search="getQuestionLib" />
          </div>

          <el-button type="primary" style="margin-bottom: 20px;" size="small"
            @click="addLibDrawerShow = true">上传题库资源</el-button>

          <div class="table" style="margin-bottom: 20px;">
            <el-table :data="tableList" border size="small">
              <el-table-column prop="id" label="序号" width="80">
              </el-table-column>
              <el-table-column prop="name" label="名称">
              </el-table-column>
              <el-table-column prop="type" label="类型" width="200">
                <template v-slot="{ row }">
                  {{ libTypeList.find(item => item.id === row.type)?libTypeList.find(item => item.id === row.type).name:"" }}
                </template>
              </el-table-column>
              <el-table-column prop="link" label="链接">
              </el-table-column>
              <el-table-column prop="address" label="操作" width="170">
                <template v-slot="{ row }">
                  <el-button type="primary" size="mini" @click="previewLib(row)">预览</el-button>
                  <span v-if="showAddBtn(row) === 1" style="margin-left: 10px;">已绑定</span>
                  <el-button v-else type="primary" size="mini" @click="addLib(row)"
                    :loading="bindingLoading && row.id === bindingId">绑定</el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <Pagin style="text-align: end;" @currentChange="currentChange" @sizeChange="sizeChange"
            :pagination="pagination" />
        </template>
        <template v-else-if="tabActive === '3'">
          <el-table :data="filterExperList" border size="small" key="boundExperimentList">
            <el-table-column prop="id" label="ID" width="80"></el-table-column>
            <el-table-column prop="name" label="名称"></el-table-column>
            <el-table-column prop="type" label="类型" width="140">
              <template v-slot="{ row }">
                {{ experTypeList?.find(item => item.id === row.type)?.name }}
              </template>
            </el-table-column>
            <el-table-column prop="resource_count" label="关联资源数" width="100"></el-table-column>
            <el-table-column prop="desc" label="描述"></el-table-column>
            <el-table-column label="操作" fixed="right" width="150">
              <template v-slot="{ row }">
                <el-button type="warning" size="small" @click="disassExper(row)">解绑</el-button>
              </template>
            </el-table-column>
          </el-table>
        </template>
        <template v-else-if="tabActive==='4'">
          <div class="transform35">
            <el-button type="primary" size="small" style="margin-right:20px" @click="addExperShow=true">添加实验</el-button>
            <Input v-model="experSearch.keyword" placeholder="模糊搜索" clearable search
              :enter-button="true"
              style="width:200px;"
              @on-search="getAllExperList" />
          </div>
          <el-table :data="allExperimentList" border size="small" key="allExperimentList">
            <el-table-column prop="id" label="ID" width="80"></el-table-column>
            <el-table-column prop="name" label="名称"></el-table-column>
            <el-table-column prop="type" label="类型" width="140">
              <template v-slot="{ row }">
                {{ experTypeList?.find(item => item.id === row.type)?.name }}
              </template>
            </el-table-column>
            <el-table-column prop="resource_count" label="关联资源数" width="100"></el-table-column>
            <el-table-column prop="desc" label="描述"></el-table-column>
            <el-table-column label="操作" fixed="right" width="100">
              <template v-slot="{ row }">
                <div v-if="nodeIdExperList.find(item=>item===row.id)">已关联</div>
                <el-button v-else type="danger" size="small" @click="assExper(row)">关联</el-button>
              </template>
            </el-table-column>
          </el-table>
          <Pagin
            style="text-align: end; margin-top: 20px;"
            :pagination="experPagination"
            @currentChange="experCurrentChange"
            @sizeChange="experSizeChange"/>
        </template>
      </div>
    </el-drawer>
    <!-- 添加题库资源抽屉 -->
    <AddLibDrawer :drawerShow="addLibDrawerShow" :drawerData="drawerData" @cancel="addLibDrawerClose()"
      @submit="addLibsubmit" />
    <!-- 添加实验抽屉 -->
    <AddExper :show="addExperShow" @close="addExperClose()" :drawerData="addExperData" />
    <!-- 预览资源抽屉 -->
    <el-drawer :visible.sync="previewDrawer" size="80%" @close="drawerPreviewPath = ''">
      <div class="drawer-title" slot="title">
        <div class="drawer-title-text">预览</div>
      </div>
      <div style="padding: 20px; height: 100%;">
        <video v-if="addFileType == 1" controls width="100%" height="100%" :src="drawerPreviewPath"
          controlslist="nodownload" style="background-color: black; padding: 0;"></video>
        <embed v-if="addFileType != 1" width="100%" :src="drawerPreviewPath" height="100%" type="application/pdf" />
      </div>
    </el-drawer>
    <!-- 添加标签抽屉 -->
    <RelatedDrawer :drawerShow="relatedDrawerShow" :relatedData="relatedData" :curSelectPoint="curSelectPoint" :curSelectLabel="curSelectLabel" :isSuper="isSuper" @drawerClose="addLabelClose()"></RelatedDrawer>
  </div>
</template>

<script>
import DeleModal from "@/components/deleteModal.vue";
import Pagin from "../../components/myPagin.vue";
import Cookies from "js-cookie";
import util from "@/utils/tools.js";
import AddLibDrawer from "./components/common/addLibDrawer.vue";
import RelatedDrawer from "./components/common/RelatedQuestionsDrawer.vue";
import AddExper from "./components/common/AddExper.vue";
import { MessageBox } from "element-ui";
export default {
  name: "mapCreat.vue",
  data() {
    return {
      Cookies: Cookies,
      // 添加标签抽屉可见性
      relatedDrawerShow:false,
      name: "",
      // 添加节点抽屉可见性
      modal: false,
      // 表格树的数据
      treeData: [],
      // tableStatus: true,
      // 图谱id
      mapId: "",
      // 当前要编辑的节点名称
      nodeName: "",
      // 当前要编辑节点的索引
      nodeNum: -1,
      // 当前要编辑的节点数据
      curNodeData: {},
      // 删除节点可见性
      deleModalStatus: false,
      modalLoading: false,
      labelList: [],
      labelLoading: false,
      labels: [],
      type: "",
      upNumber: ["一", "二", "三", "四", "五", "六"],
      maxLengh: 1,
      weight: "20",
      cacheData: [],
      addLabelModal: false,
      pageSize: 20,
      page: 1,
      total: 0,
      selectData: [],
      curLabelData: [],
      categoryList: [], //一级分类
      curCategoryId: "-1", //当前分类
      secondCategoryList: [], //二级分类
      cursecondCategoryId: "-1", //当前二级分类
      curThirdCategoryId: "-1", //当前三级分类
      thirdCategoryList: [],
      weightTotal: [], //权重合计
      keyword: "",
      categoryId: "",
      mapinfo: {},
      addDataModal: false,
      // dataColumns: [
      //   {
      //     type: "selection",
      //     width: 60,
      //     align: "center",
      //   },
      //   {
      //     title: "试题ID",
      //     key: "id",
      //     width: 120,
      //   },
      //   {
      //     title: this.$t("dataset_data_table_title"),
      //     key: "title",
      //   },
      // ],
      dataList: [],
      dataKeyword: "",
      dataPage: 1,
      dataTotal: 0,
      dataPageSize: 10,
      dataLoading: false,
      cacheSelectData: {},
      selectModalDataList: [],
      selectDataList: [],
      labelType: "",
      uploadModal: false,
      uploadFile: "",
      uploadFileId: "",
      curData: {},
      statusObj: {
        0: "待上架",
        1: "已上架",
        2: "已下架"
      },
      nodeDataColumns: [
        {
          type: "selection",
          width: 60,
          align: "center",
        },
        {
          title: "ID",
          key: "data_id",
          width: 80,
        },
        {
          title: "类型",
          key: "title",
          width: 80,
          render: (h, params) => {
            return h(
              "span",
              params.row.data && this.dataTypes[params.row.data.type]
            );
          },
        },
        {
          title: "题目",
          key: "title",
          minwidth: 160,
          render: (h, params) => {
            return h("span", params.row.data && params.row.data.title);
          },
        },
        {
          title: "答案",
          key: "title",
          render: (h, params) => {
            let arr = [];
            let correctData = params.row.dataOptions.filter((item) => {
              return item.is_correct == "1";
            });
            correctData.forEach((item, index) => {
              arr.push(h("p", item.name));
            });
            return h("div", arr);
          },
        },
        {
          title: "考点",
          key: "title",
          minwidth: 160,
          render: (h, params) => {
            let arr = [];
            if (params.row.data.labelKeypoints) {
              params.row.data.labelKeypoints.forEach((item, index) => {
                arr.push(h("p", item.label.name));
              });
            }

            return h("div", arr);
          },
        },
      ],
      nodeDataList: [], //标签对应的题目
      nodeDataPageSize: 10,
      nodeDataTotal: 0,
      nodeDataPage: 1,

      optionList: ["A", "B", "C", "D", "E", "F"],
      dataTypes: {},
      bookImg: "",
      coverFileId: "",
      bookAuthor: "",
      bookPress: "",
      lastNode: {},
      nodeDataLoading: false,
      // addDataTabList:[
      //   {
      //     name:'关联试题',
      //     id:'1'
      //   },
      //   {
      //     name:'新增试题',
      //     id:'2'
      //   }
      // ],
      addDataSubTabList: [
        {
          name: "关联试题",
          id: "2",
        },
        {
          name: "关联考点",
          id: "1",
        },
        {
          name: "关联关键词",
          id: "0",
        },
      ],
      curAddDataSubTabId: 2,
      selectDataLable: [],
      curSelectPoint: {}, //当前选中考点
      curSelectLabel: {}, //当前选中关键词
      dataId: "",
      unRelationDataList: [],
      labelSelect: "",
      dataListLoading: false,
      unRelationDataColumns: [
        {
          type: "selection",
          width: 60,
          align: "center",
        },
        {
          title: "ID",
          key: "id",
          width: 80,
        },
        {
          title: "类型",
          key: "title",
          width: 80,
          render: (h, params) => {
            return h("span", this.dataTypes[params.row.type]);
          },
        },
        {
          title: "题目",
          key: "title",
          minwidth: 160,
          render: (h, params) => {
            return h("span", params.row.title);
          },
        },
      ],
      unRelationDataPage: 1,
      unRelationDataTotal: 0,
      unRelationDataPageSize: 10,
      desc: "",
      imgUrl: "",
      addFileModal: false,
      videoPath: "",
      pptPath: "",
      addFileType: "-1",
      addFileList: [],
      batchAddFileModal: false,
      batchTabList: [
        {
          name: "ppt",
          id: "2",
        },
        {
          name: "video",
          id: "1",
        },
        {
          name: "实操",
          id: "3",
        },
      ],
      curBatchTab: "2",
      batchColumns: [],
      batchPptColumns: [
        {
          title: "ID",
          key: "id",
        },
        {
          title: "课程内容",
          key: "data_id",
          render: (h, params) => {
            return h("span", params.row.node && params.row.node.node.name);
          },
        },
        {
          title: "课件名称",
          key: "data_id",
          render: (h, params) => {
            return h("span", params.row.name);
          },
        },
        {
          title: "更新时间",
          key: "data_id",
          render: (h, params) => {
            return h(
              "span",
              util.timeFormatter(
                new Date(+params.row.updated_at * 1000),
                "yyyy-MM-dd hh:mm"
              )
            );
          },
        },
        {
          title: "操作",
          render: (h, params) => {
            return h("div", [
              h(
                "Button",
                {
                  props: {
                    type: "error",
                    size: "small",
                  },
                  style: {
                    marginRight: "5px",
                  },
                  on: {
                    click: () => {
                      this.batchDele(params.row);
                    },
                  },
                },
                "删除"
              ),
            ]);
          },
        },
      ],
      batchVideoColumns: [
        {
          title: "ID",
          key: "id",
        },
        {
          title: "课程内容",
          key: "data_id",
          render: (h, params) => {
            return h("span", params.row.node && params.row.node.node.name);
          },
        },
        {
          title: "视频名称",
          key: "data_id",
          render: (h, params) => {
            return h("span", params.row.name);
          },
        },
        {
          title: "更新时间",
          key: "data_id",
          render: (h, params) => {
            return h(
              "span",
              util.timeFormatter(
                new Date(+params.row.updated_at * 1000),
                "yyyy-MM-dd hh:mm"
              )
            );
          },
        },
        {
          title: "操作",
          render: (h, params) => {
            return h("div", [
              h(
                "Button",
                {
                  props: {
                    type: "error",
                    size: "small",
                  },
                  style: {
                    marginRight: "5px",
                  },
                  on: {
                    click: () => {
                      this.batchDele(params.row);
                    },
                  },
                },
                "删除"
              ),
            ]);
          },
        },
      ],
      batchEnchiridionColumns: [
        {
          title: "ID",
          key: "id",
        },
        {
          title: "课程内容",
          key: "data_id",
          render: (h, params) => {
            return h("span", params.row.node && params.row.node.node.name);
          },
        },
        {
          title: "实操名称",
          key: "data_id",
          render: (h, params) => {
            return h("span", params.row.name);
          },
        },
        {
          title: "更新时间",
          key: "data_id",
          render: (h, params) => {
            return h(
              "span",
              util.timeFormatter(
                new Date(+params.row.updated_at * 1000),
                "yyyy-MM-dd hh:mm"
              )
            );
          },
        },
        {
          title: "操作",
          render: (h, params) => {
            return h("div", [
              h(
                "Button",
                {
                  props: {
                    type: "error",
                    size: "small",
                  },
                  style: {
                    marginRight: "5px",
                  },
                  on: {
                    click: () => {
                      this.batchDele(params.row);
                    },
                  },
                },
                "删除"
              ),
            ]);
          },
        },
      ],
      batchData: [],
      batchFileName: "",
      batchFileId: "",
      importBatch: "",
      batchPage: 1,
      batchPageSize: 10,
      batchTotal: 0,
      curFileIndex: 0,
      newFileList: [],
      checkImgModal: false,
      sort: "0",
      fileLoading: false,
      mapType: "",
      practicalUrl: "",
      isSuper: false,
      // 切换题库抽屉的关闭
      drawerShow: false,
      // 切换题库表单
      switchQuestionBankForm: {
        map_id: "",
        category_id: "",
      },
      // 获取分类的表单数据
      questionCateForm: {
        type: "0",
        category_id: "-1",
        category_second_id: "-1",
      },
      switchQuestionLoading: false,
      categoryLevel1: [],
      categoryLevel2: [],
      categoryLevel3: [],
      categoryTypeList: [],
      // 题库数据
      tableData: [],
      // 选中要切换的题库行
      currentRow: null,
      // 添加标签抽屉可见性
      addLabelsDrawerShow: false,
      tabActive: "0",
      // 分页器数据
      pagination: {
        page: 1,
        pageSize: 10,
        total: 0
      },
      // 表格数据
      tableList: [],
      // 资源类型数据
      libTypeList: [],
      // 资源关键字搜索
      libKeyword: "",
      // 添加题库资源抽屉可见性
      addLibDrawerShow: false,
      previewDrawer: false,
      drawerPreviewPath: "",
      // 绑定按钮loading
      bindingLoading: false,
      bindingId: "",
      // 解绑按钮loading
      unbindingLoading: false,
      unbindingId: "",
      loading3: false,
      uploadProgress: 0,
      drawerTitle: "",
      addAndBind:false,
      level1Id:"",
      level2Id:"",
      level3Id:"",
      questionType:"",
      nodeId:"",
      relatedData:{
        map_id:"",
        node_id:"",
        category_id:""
      },
      // 已绑定的实验列表
      filterExperList:[],
      // 全部实验
      allExperimentList:[],
      // 全部实验搜索数据
      experSearch:{
        keyword:""
      },
      // 全部实验分页器
      experPagination:{
        page:1,
        pageSize:10,
        total:0
      },
      experTypeList:[],
      // 实验类型
      addExperType:"-1",
      addExperShow:false,
      nodeLength:'',  //控制最多几级节点
      // 节点已绑定的资源列表
      nodeIdSourceList:[],
      // 节点已绑定的实验列表
      nodeIdExperList:[],
    };
  },
  components: {
    DeleModal,
    Pagin,
    AddLibDrawer,
    RelatedDrawer,
    AddExper
  },
  computed:{
    drawerData(){
      return {
        libType:this.addFileType,
        level1Id:this.level1Id,
        level2Id:this.level2Id,
        level3Id:this.level3Id,
        type:this.questionType
      }
    },
    addExperData(){
      return {
        type:this.addExperType
      }
    }
  },
  created() {
    this.mapId = this.$route.query.id;
    this.mapType =
      this.$route.path == "/manager/dataset/mapCreate" ? "course" : "";
    this.isSuper =
      this.$route.path == "/manager/dataset/mapCreate" || this.$route.path=="/teacher/dataset/mapCreate" ? false : true;
    this.nodeLength = this.$route.query.nodeLength;
    this.getInfo();
    this.getCategoryList();
    this.getTypsList();
    this.getFirstList();
    // this.getLibType();
  },
  beforeDestroy(){
    this.update();
  },
  methods: {
    addLabelClose(){
      this.getInfo();
      this.relatedDrawerShow=false;
    },
    // 获取资源类型数据
    getLibType(){
      this.api.dataset.libType().then(res=>{
        const arr=[];
        Object.keys(res.types).forEach(key=>{
          arr.push({
            id:key,
            name:res.types[key]
          });
        });
        arr.unshift({
          id:"-1",
          name:"全部"
        });
        this.libTypeList=arr;
      });
    },
    // tab样式
    tabStyle(value) {
      let className = "";
      if (value === this.tabActive) {
        className += "tabActive";
      }
      if (this.type === "add") {
        className += " disable";
      }
      return className;
    },
    // 添加资源抽屉关闭
    addLibDrawerClose() {
      this.addLibDrawerShow = false;
    },
    addExperClose(){
      this.addExperShow=false;
      this.getAllExperList();
    },
    addLibAndBinding(){
      this.addAndBind=true;
      this.addLibDrawerShow=true;
    },
    // 添加题库资源确定添加
    addLibsubmit(list) {
      list.forEach(item => {
        this.addLib(item);
      });
      this.addLibDrawerShow = false;
      this.getQuestionLib();
    },
    changeFileType(name, type) {
      this.addFileType = type;
      this.getQuestionLib();
      this.getNodeSource();
    },
    changeExperType(id){
      this.addExperType=id;
      this.getNodeExper();
      this.getAllExperList();
    },
    // 是否显示添加按钮
    showAddBtn(row) {
      const { nodeIdSourceList } = this;
      // 已经绑定返回1
      if (nodeIdSourceList.find(item => item === row.id)) {
        return 1;
      }
    },
    // 绑定题库资源
    addLib(row) {
      const { addFileType } = this;
      this.bindingId = row.id;
      this.bindingLoading = true;
      this.api.dataset.sourceToMap({
        type: row.type,
        ids: row.id,
        map_id: this.mapId,
        node_id: this.curNodeData.node.id,
        site_id: "-1"
      }).then(() => {
        this.changeFileType("", addFileType);
        this.bindingLoading = false;
        this.$Message.success("绑定成功");
      }).catch(() => {
        this.bindingLoading = false;
      });
    },
    // 预览资源
    previewLib(row) {
      this.addFileType = row.type;
      this.drawerPreviewPath = row.show_url || row.path;
      this.previewDrawer = true;
    },
    // 获取题库资源列表
    getQuestionLib() {
      const { pagination, isSuper } = this;
      const params = {
        ...pagination,
        type: this.addFileType,
        keyword: this.libKeyword,
        category_id: this.mapinfo.category_id,
        user:"-1"
      }
      if (isSuper) {
        params.site_id = "-1"
      }
      this.api.dataset.datasetLibList(params).then(res => {
        this.tableList = res.list || [];
        const arr = [];
        Object.keys(res.types).forEach(key => {
          arr.push({
            id: key,
            name: res.types[key]
          });
        });
        arr.unshift({
          id:"-1",
          name:"全部"
        });
        this.libTypeList = arr;
        pagination.total = Number(res.count);
      });
    },
    getAllExperList(){
      const { experPagination,experSearch,addExperType }=this;
      this.api.dataset.getExperimentList({
        page: experPagination.page,
        page_size: experPagination.pageSize,
        site_id: "-1",
        user_id: "-1",
        type:addExperType,
        ...experSearch
      }).then(res => {
        const arr = [];
        Object.keys(res.types).forEach(key => {
          arr.push({
            id: key,
            name: res.types[key]
          });
        });
        arr.unshift({
          id: "-1",
          name: "全部"
        });
        this.experTypeList = arr;
        this.allExperimentList = res.list || [];
        experPagination.total = Number(res.count);
      });
    },
    experCurrentChange(page){
      const { experPagination }=this;
      experPagination.page=page;
      this.getAllExperList();
    },
    experSizeChange(size){
      const { experPagination }=this;
      experPagination.pageSize=size;
      this.getAllExperList();
    },
    // 当前页改变
    currentChange(page) {
      const { pagination } = this;
      pagination.page = page;
      this.getQuestionLib();
    },
    // 每页条数改变
    sizeChange(pageSize) {
      const { pagination } = this;
      pagination.pageSize = pageSize;
      this.getQuestionLib();
    },
    // 预览和绑定资源切换
    changeTab(tabActive) {
      if (this.type === "add") return;
      this.getQuestionLib();
      this.getAllExperList();
      this.tabActive = tabActive;
      console.log(this.tabActive);
    },
    // 开始匹配
    startMatch() {
      const { switchQuestionBankForm } = this;
      let data = {
        map_id: switchQuestionBankForm.map_id,
      };
      this.api.dataset.datasetMapAiMatchStart(data).then(() => {
        this.$Message.success("开始匹配");
      });
    },
    // 删除所有章节
    deleteAllNode() {
      MessageBox({
        type: "warning",
        title: "注意",
        message:
          "清空章节时，系统会自动备份原图谱所有内容。若需使用原图谱，请到图谱列表中查找。",
        showCancelButton: true,
      }).then(() => {
        this.api.dataset
          .deleteAllNode({
            map_id: this.$route.query.id || "",
          })
          .then(() => {
            this.$Message.success("删除章节成功");
            this.getInfo();
            this.getCategoryList();
            this.getTypsList();
            this.getFirstList();
          });
      });
    },
    // 删除所有数据
    deleteAllData() {
      MessageBox({
        type: "warning",
        title: "注意",
        message:
          "清空数据时，系统会自动备份原图谱所有内容。若需使用原图谱，请到图谱列表中查找。",
        showCancelButton: true,
      }).then(() => {
        this.api.dataset
          .deleteAllData({
            map_id: this.$route.query.id || "",
          })
          .then(() => {
            this.$Message.success("删除数据成功");
            this.getInfo();
            this.getCategoryList();
            this.getTypsList();
            this.getFirstList();
          });
      });
    },
    changeOk() {
      const { switchQuestionBankForm } = this;
      if (!switchQuestionBankForm.category_id) {
        return this.$Message.error("请选择题库");
      }
      this.switchQuestionLoading = true;
      this.api.dataset
        .changeCategory(switchQuestionBankForm)
        .then((res) => {
          this.switchQuestionLoading = false;
          this.drawerShow = false;
          this.getInfo();
          this.getCategoryList();
          this.$Message.success("切换成功");
        })
        .catch(() => {
          this.switchQuestionLoading = false;
        });
    },
    // 取消切换题库
    cancelSwitch() {
      this.drawerShow = false;
    },
    getTypsList() {
      this.api.dataset
        .categoryForm({
          site_id: "-1",
          user_id: "-1",
          type: "",
        })
        .then((res) => {
          // 类型数据转为数组
          const typeList = [];
          Object.keys(res.categoryTypes).forEach((key) => {
            typeList.push({
              id: key,
              name: res.categoryTypes[key],
            });
          });
          this.categoryTypeList = typeList;
        });
    },
    // 获取一级分类数据
    getFirstList() {
      const { questionCateForm } = this;
      this.api.dataset
        .categoryForm({
          site_id: "-1",
          user_id: "-1",
          type: questionCateForm.type,
        })
        .then((res) => {
          this.categoryLevel1 = res.categoryFirstList || [];
          questionCateForm.category_id = res.categoryFirstList[0].id;
          this.getSecondList();
        });
    },
    // 获取二级分类
    getSecondList() {
      const { questionCateForm } = this;
      let data = {
        category_id: questionCateForm.category_id,
        type: questionCateForm.type,
        is_hide_children: 1,
        site_id: -1,
        user_id: -1,
      };
      this.api.dataset.categoryForm(data).then((res) => {
        this.categoryLevel2 = res.categorySecondList || [];
        if (res.categorySecondList.length) {
          questionCateForm.category_second_id = res.categorySecondList[0].id;
        }
        this.getThreeList();
      });
    },
    // 获取三级分类
    getThreeList() {
      const { questionCateForm } = this;
      let data = {
        category_id: questionCateForm.category_id,
        category_second_id: questionCateForm.category_second_id,
        is_hide_children: 1,
        type: questionCateForm.type,
        site_id: -1,
        user_id: -1,
      };
      this.api.dataset.categoryForm(data).then((res) => {
        //三级标签
        this.tableData = res.categoryThirdList || [];
      });
    },
    // 选择题库
    selectQB(row) {
      this.currentRow = row.id;
      this.switchQuestionBankForm.category_id = row.id;
    },
    tableRowClassName({ row }) {
      if (row.id === this.currentRow) {
        return "success-row";
      } else {
        return "";
      }
    },
    // 查询更改
    searchChange(value, key) {
      const { questionCateForm } = this;
      if (key === "type") {
        questionCateForm.type = value;
        this.getFirstList();
      } else if (key === "category_id") {
        questionCateForm.category_id = value;
        this.getSecondList();
      } else if (key === "category_second_id") {
        questionCateForm.category_second_id = value;
        this.getThreeList();
      }
    },
    getInfo() {
      let params = {
        id: this.mapId,
      };
      if (this.isSuper) {
        params.site_id = "-1";
        params.user_id = "-1";
      }
      this.loading = true;
      this.api.dataset
        .mapDetail(params)
        .then((res) => {
          console.warn("节点数据",res);
          this.mapinfo = res.info;
          this.switchQuestionBankForm.map_id = res.info.id;
          this.name = res.info.name;
          this.bookImg = res.info.book_img;
          this.bookAuthor = res.info.book_author;
          this.bookPress = res.info.book_press;
          // this.categoryId = res.info.category_id > 0 ? res.info.category_id : this.$route.query.categoryId;
          this.categoryId = res.info.category_id;
          this.level1Id=res.info.first_category_id;
          this.level2Id=res.info.second_category_id;
          this.level3Id=res.info.category_id;
          this.questionType=res.info.category.type;
          // fn && fn();
          this.treeData = res.info.table_tree;
          this.cacheData = JSON.parse(JSON.stringify(this.treeData));
          let length = 0;
          this.treeData.filter(function (item) {
            if (item.length > length) {
              return (length = item.length);
            }
          });

          this.maxLengh = length > 2 ? length - 1 : 1;

          let dataLen = this.cacheData.length;
          let addRow = 0; //一共添加了多少行

          //children_count 如果当前行存在 且下一行为空在children_count 行增加一位
          //最后一列不管  从倒数第二列开始循环
          if (this.cacheData.length) {
            this.cacheData.forEach((item, index) => {
              for (let i = item.length - 1; i >= 0; i--) {
                let arr = [];

                //不是最后一条  且 当前位置大于下一条的总长度
                // console.log(item[i] ? item[i].node.name : '','3333')
                // console.log(index < this.cacheData.length - 1,i > (this.cacheData[index + 1].length - 1) ,this.cacheData[index + 1].length)
                if (
                  index < this.cacheData.length - 1 &&
                  i > this.cacheData[index + 1].length - 1
                ) {
                  for (let j = 0; j <= i; j++) {
                    if (j == i) {
                      arr.push({
                        id: -1,
                      });
                    } else {
                      arr.push("");
                    }
                  }

                  this.setMergeCallNum(index + addRow, i - 1);
                } else {
                  //最后一条数据
                  //不是最后一条 且不是这一条的第一个 且当前位置和下一条的当前位置都有值
                  // console.log((this.cacheData[index + 1][i].id ),'是否为真')
                  if (
                    index == this.cacheData.length - 1 ||
                    (i > 0 &&
                      item[0] &&
                      this.cacheData[index + 1][0] &&
                      item[i]) ||
                    (i > 0 &&
                      item[i] &&
                      this.cacheData[index + 1][i] &&
                      this.cacheData[index + 1][i - 1]) ||
                    (!item[i] &&
                      this.cacheData[index + 1][i] &&
                      this.cacheData[index + 1][i - 1])
                  ) {
                    for (let j = 0; j <= i; j++) {
                      if (j == i) {
                        arr.push({
                          id: -1,
                        });
                      } else {
                        arr.push("");
                      }
                    }
                    // console.log(arr,item[i] ? item[i].node.name : item[i],'11111')
                    this.setMergeCallNum(index + addRow, i - 1);
                  }
                }
                // console.log(arr,'dddd')
                if (arr.length) {
                  this.treeData.splice(index + 1 + addRow, 0, arr);
                  addRow++;
                }
              }
            });
          } else {
            this.treeData.push([
              {
                id: -1,
                label_keywords_count: "",
                datas_count: "",
                label_keypoints_count: 0,
              },
            ]);
          }
          this.treeData.forEach((item, index) => {
            // console.log(item,'+++++++++++')
            let dataNum = {};
            if (item[item.length - 1].id != -1) {
              // console.log(item[item.length -1].node.name,item[item.length -1],'item[item.length -1]')
              let obj = {
                id: -1,
                label_keywords_count:
                  item[item.length - 1].label_keywords_count,
                datas_count: item[item.length - 1].datas_count || [],
                label_keypoints_count:
                  item[item.length - 1].label_keypoints_count || 0,
              };
              item.push(obj);
              dataNum.label_keywords_count =
                item[item.length - 1].label_keywords_count;
              dataNum.datas_count = item[item.length - 1].datas_count;
              dataNum.label_keypoints_count =
                item[item.length - 1].label_keypoints_count;
            }
            if (item.length < 3) {
              let len = (length + 1 > 3 ? length + 1 : 3) - item.length;
              for (let i = 0; i < len; i++) {
                item.push({
                  id: "",
                  ...dataNum,
                });
              }
            } else if (item.length < length + 1) {
              let len = length - item.length + 1;
              for (let i = 0; i < len; i++) {
                item.push({
                  id: "",
                  ...dataNum,
                });
              }
            }
          });
          // console.log(this.treeData,'this.treeData')

          this.weightTotal = [];
          this.treeData.forEach((item) => {
            item.forEach((sItem, index) => {
              if (sItem.weight) {
                if (this.weightTotal[index]) {
                  this.weightTotal[index] += Number(sItem.weight);
                } else {
                  this.weightTotal[index] = Number(sItem.weight);
                }
              }
            });
          });
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    setMergeCallNum(index, length) {
      let len = 0; //判断有没有找到数据  只有找到了才继续找上一位的

      for (let ind = index; ind >= 0; ind--) {
        let status = false;
        if (this.treeData[ind][0]) {
          status = true;
        }
        for (let j = length; j >= 0; j--) {
          //index以上的所有元素children_count + 1；
          if (this.treeData[ind][j] && this.treeData[ind][j].id != -1) {
            this.treeData[ind][j].children_count =
              Number(this.treeData[ind][j].children_count) + 1;
            // console.log(this.treeData[ind][j].node.name,this.treeData[ind][j].children_count)
            len++;
          } else {
            if (j > 0) {
              status = true;
              this.setMergeCallNum(ind - 1, length - len);
              break;
            }
          }
          if (j == 0 && this.treeData[ind][0]) {
            status = true;
            break;
          }
        }
        if (status) break;
      }
    },
    arraySpanMethod({ row, column, rowIndex, columnIndex }) {
      // let num = Math.floor(columnIndex/2);
      let num = Math.floor(columnIndex);

      if (row[num] && row[num].children_max_count == "0") {
        row[num].children_max_count =
          Number(row[num].children_max_count) + 1 + "";
      }

      if (row[num]) {
        return {
          rowspan: Number(row[num].children_max_count),
          colspan: 1,
        };
      } else {
        if (num > row.length - 1) {
          return {
            rowspan: 1,
            colspan: 1,
          };
        } else {
          return {
            rowspan: 0,
            colspan: 0,
          };
        }
      }
    },
    move(data, curRowIndex, direction) {
      //index 当前行 data 当前数据 curRowIndex 当前列 direction 指定节点移动方向,1上2下
      let params = {
        map_id: this.mapId,
        id: data[curRowIndex].id,
        direction: direction,
      };
      if (this.isSuper) {
        params.site_id = "-1";
        params.user_id = "-1";
      }
      this.api.dataset.mapMoveNode(params).then((res) => {
        this.getInfo();
      });
    },
    addRootNode() {
      this.nodeNum = -1;
      this.modal = true;
      this.type = "add";
    },
    addNode(data, curRowIndex, index) {
      if((curRowIndex >= (this.nodeLength - 1)) && this.nodeLength ){
        this.$Message.warning('最多只能添加两级节点～');
        return;
      }
      if (index == this.treeData.length - 1) {
        this.nodeNum = -1;
      } else {
        this.nodeNum = curRowIndex;

        if (!data[curRowIndex]) {
          for (let i = index; i >= 0; i--) {
            if (this.treeData[i][curRowIndex]) {
              this.curNodeData = this.treeData[i][curRowIndex];
              break;
            }
          }
        } else {
          this.curNodeData = data[curRowIndex];
        }
      }
      this.imgUrl = "";
      this.type = "add";
      this.addFileModal = true;
      this.nodeName = "";
      this.weight = "20";
      this.desc = "";
      this.nodeId=""
    },
    edit(data, curRowIndex) {
      const typeList = ["vedio_info", "ppt_info", "enchiridion_info"];
      this.type = "edit";
      this.tabActive="0";
      this.drawerTitle = `编辑节点-${data[curRowIndex].node.name}`;
      this.curNodeData = data[curRowIndex];
      this.nodeNum = curRowIndex;
      this.nodeName = this.curNodeData.node.name;
      this.weight = this.curNodeData.weight;
      this.desc = this.curNodeData.desc;
      this.imgUrl = this.curNodeData.img;
      this.sort = this.curNodeData.sort;
      this.nodeId=this.curNodeData.node.id;
      if (curRowIndex == 3) {
        this.labels = this.curNodeData.label_ids && this.curNodeData.label_ids.split(',')
        this.getLabelList('', this.labels)
      }
      // 将所有文件添加到列表
      typeList.forEach(type => {
        if (this.curNodeData[type].length) {
          this.curNodeData[type].forEach(item => {
            if (!item.path.indexOf("https") === 0) {
              item.path = this.constant.URL + "/uploadfile/show/?file=" + item.path;
            }
          });
        }
      });
      this.addFileType = "-1";
      this.addExperType="-1";

      this.nodeIdSourceList=[];
      this.fileList=[];
      this.filterExperList=[];
      this.nodeIdExperList=[];
      this.getNodeSource();
      this.getNodeExper();
      this.addFileModal = true;
    },

    // 获取节点已绑定的资源
    getNodeSource() {
      this.api.dataset.getNodeSource({
        type:this.addFileType,
        map_id:this.mapId,
        node_id:this.curNodeData.node.id,
      }).then(res=>{
        this.nodeIdSourceList=util.objToArr(res.source_ids);
        this.addFileList=res.list || [];
      });
    },
    // 获取节点已绑定的实验
    getNodeExper(){
      this.api.dataset.getNodeExper({
        type:this.addExperType,
        map_id:this.mapId,
        node_id:this.curNodeData.node.id,
      }).then(res=>{
        console.log(res);
        this.nodeIdExperList=util.objToArr(res.experiment_ids);
        this.filterExperList=res.list || [];
      });
    },
    confirm(closeType) {
      //确认添加子节点
      if (this.type == "add") {
        if (!this.nodeName) {
          this.$Message.warning(this.$t("dataset_map_create_warn_no_node"));
          return;
        }
        if (!this.weight) {
          this.$Message.warning("请输入权重");
          return;
        }
        if (this.weight > 100 || this.weight < 0) {
          this.$Message.warning("请输入1-100的整数");
          return;
        }
        // if(this.nodeNum == 2 && !this.labels.length){
        //   this.$Message.warning(this.$t('dataset_map_create_warn_no_label'));
        //   return;
        // }
        let parent_id;
        if (this.nodeNum == -1) {
          //根节点
          parent_id = "0";
        } else {
          //非根节点
          parent_id = this.curNodeData.id;
        }
        let params = {
          map_id: this.mapId,
          parent_id: parent_id,
          name: this.nodeName,
          weight: this.weight,
          desc: this.desc,
          img: this.imgUrl,
          sort: "0",
        };
        // if(this.nodeNum == 2){
        //   params.label_ids = this.labels.join(',');
        // }
        if (this.isSuper) {
          params.site_id = "-1";
          params.user_id = "-1";
        }
        this.api.dataset.mapCreateNode(params).then((res) => {
          this.modal = false;
          if (closeType) {
            this.addFileModal = false;
          }
          this.$Message.success("保存成功");
          this.imgUrl = "";
          this.type = "add";
          this.nodeName = "";
          this.width = "20";
          this.desc = "";
          this.getInfo();
        });
      } else if (this.type == "edit") {
        if (!this.nodeName) {
          this.$Message.warning(this.$t("dataset_map_create_warn_no_node"));
          return;
        }
        if (!this.weight) {
          this.$Message.warning("请输入权重");
          return;
        }
        if (this.weight > 100 || this.weight < 0) {
          this.$Message.warning("请输入1-100的整数");
          return;
        }
        // if(this.nodeNum == 3 && !this.labels.length){
        //   this.$Message.warning(this.$t('dataset_map_create_warn_no_label'));
        //   return;
        // }
        let parmas = {
          map_id: this.mapId,
          name: this.nodeName,
          id: this.curNodeData.id,
          node_id: this.curNodeData.node.id,
          parent_id: this.curNodeData.node_parent_id,
          weight: this.weight,
          desc: this.desc,
          img: this.imgUrl,
          sort: "0",
        };
        // if(this.nodeNum == 3){
        //   params.label_ids = this.labels.join(',');
        // }
        if (this.isSuper) {
          parmas.site_id = "-1";
          parmas.user_id = "-1";
        }
        this.api.dataset.mapUpdateNode(parmas).then((res) => {
          this.modal = false;
          this.$Message.success("保存成功");
          if (closeType) {
            this.addFileModal = false;
          }
          this.getInfo();
        });
      }
    },
    dele() {
      // this.deleModalStatus = true;
      // this.curNodeData = data[curRowIndex];
      // console.log(data,curRowIndex,this.curNodeData)
      MessageBox({
        type: "warning",
        title: "注意",
        message:
          "确认删除节点吗?删除后子节点一起删除",
        showCancelButton: true,
      }).then(() => {
        this.confirmDele();
      });
    },
    cancelDele() {
      this.deleModalStatus = false;
    },
    confirmDele() {
      //确认删除
      let params = {
        map_id: this.mapId,
        id: this.curNodeData.id,
        node_id: this.curNodeData.node.id,
        parent_id: this.curNodeData.node_parent_id,
      };
      if (this.isSuper) {
        params.site_id = "-1";
        params.user_id = "-1";
      }
      this.api.dataset.mapDeleNode(params).then((res) => {
        this.deleModalStatus = false;
        this.modal = false;
        this.addFileModal = false;
        this.getInfo();
      });
    },
    update() {
      //更新知识图谱名称
      if (!this.name) {
        this.$Message.warning("请输入教材名称");
        return;
      }
      // if (!this.bookAuthor && this.mapType != 'course') {
      //   this.$Message.warning('请输入作者');
      //   return;
      // }

      // if (!this.bookPress && this.mapType != 'course') {
      //   this.$Message.warning('请输入出版社');
      //   return;
      // }
      // if (!this.bookImg) {
      //   this.$Message.warning('请上传封面');
      //   return;
      // }

      let params = {
        id: this.mapId,
        name: this.name,
        book_author: this.bookAuthor,
        book_press: this.bookPress,
        book_img: this.bookImg,
      };
      if (this.isSuper) {
        params.site_id = "-1";
        params.user_id = "-1";
      }
      this.api.dataset.mapUpdate(params).then((res) => {
        this.$Message.success("保存成功");
        this.getInfo();
      });
    },
    getLabelList(query, ids) {
      let params = {
        pageSize: this.pageSize,
        page: this.page,
        keyword: this.keyword,
        category_id: this.categoryId,
        type: this.labelType,
      };

      // if(this.curThirdCategoryId && this.curThirdCategoryId != -1){
      //   params.category_id = this.curThirdCategoryId;
      // }else if(this.cursecondCategoryId && this.cursecondCategoryId != -1){
      //   params.category_id = this.cursecondCategoryId;
      // }else if(this.curCategoryId && this.curCategoryId != -1){
      //   params.category_id = this.curCategoryId;
      // }
      if (this.isSuper) {
        params.site_id = "-1";
        params.user_id = "-1";
      }
      this.labelLoading = true;
      this.api.dataset
        .labelList(params)
        .then((res) => {
          this.labelLoading = false;
          this.labelList = res.list;
          // console.log(this.labelList,'this.labelListthis.labelListthis.labelList')
          this.total = Number(res.count);

          this.labelList.forEach((item) => {
            if (this.labelType == "1") {
              if (this.selectData.length) {
                let ind = this.selectData.findIndex((sItem) => {
                  return item.id == sItem.id;
                });
                if (ind != -1) {
                  this.$set(item, "isSelect", true);
                } else {
                  this.$set(item, "isSelect", false);
                }
              } else {
                this.$set(item, "isSelect", false);
              }
            } else {
              if (this.selectDataLable.length) {
                let ind = this.selectDataLable.findIndex((sItem) => {
                  return item.id == sItem.id;
                });
                if (ind != -1) {
                  this.$set(item, "isSelect", true);
                } else {
                  this.$set(item, "isSelect", false);
                }
              } else {
                this.$set(item, "isSelect", false);
              }
            }
          });
        })
        .catch((err) => {
          this.labelLoading = false;
        });
    },
    remoteMethod(query, data) {
      //模糊搜索
      if (query !== "") {
        this.getLabelList(query);
      } else {
        this.labelList = [];
      }
    },
    changePage(page) {
      this.page = page;

      this.getLabelList();
    },
    changePageSize(pageSize) {
      this.page = 1;
      this.pageSize = pageSize;
      this.getLabelList();
    },
    selectDataFn(data) {
      if (data.isSelect) {
        this.$set(data, "isSelect", false);
        // if(this.labelType == '1'){
        //   let ind = this.selectData.findIndex((sItem)=>{
        //     return data.id == sItem.id;
        //   })
        //   if(ind != -1){
        //     this.$delete(this.selectData,ind);
        //   }
        // }else{
        //   let ind = this.selectDataLable.findIndex((sItem)=>{
        //     return data.id == sItem.id;
        //   })
        //   if(ind != -1){
        //     this.$delete(this.selectDataLable,ind);
        //   }
        // }
        this.nodeDelLabelData(data.id);
      } else {
        this.$set(data, "isSelect", true);
        // if(this.labelType == '1'){
        //   this.selectData.push({
        //     name:data.name,
        //     id:data.id
        //   });
        // }else{
        //   this.selectDataLable.push({
        //     name:data.name,
        //     id:data.id
        //   });
        // }
        this.nodeAddLabelData(data.id);
      }
    },
    nodeAddLabelData(labelId) {
      let params = {
        map_id: this.mapId,
        node_id: this.lastNode.id,
        label_id: labelId,
        type: this.labelType,
      };
      if (this.isSuper) {
        params.site_id = "-1";
        params.user_id = "-1";
      }
      this.api.dataset.datasetMapNodeAddLabel(params).then((res) => {
        // if(this.labelType == 1){//考点
        //   this.selectData.push(res.info);
        // }else{  //关键词
        //   this.selectDataLable.push(res.info);
        // }
        this.getNodeDataList();
      });
    },
    nodeDelLabelData(labelId) {
      let params = {
        map_id: this.mapId,
        node_id: this.lastNode.id,
        label_id: labelId,
        type: this.labelType,
      };
      if (this.isSuper) {
        params.site_id = "-1";
        params.user_id = "-1";
      }
      this.api.dataset.datasetMapNodeDelLabel(params).then((res) => {
        // if(this.labelType == 1){  //考点
        //     let ind = this.selectData.findIndex((item)=>{
        //       return item.id == labelId;
        //     });
        //     this.$delete(this.selectData,ind)
        // }else{  //关键词
        //   let ind = this.selectDataLable.findIndex((item)=>{
        //     return item.id == labelId;
        //   });
        //   this.$delete(this.selectDataLable,ind)
        // }

        this.getNodeDataList();
        this.labelList.forEach((item) => {
          if (item.id == labelId) {
            this.$set(item, "isSelect", false);
          }
        });
      });
    },
    addLabel(data, type) {
      // if(data[1].id == '-1' || !data[1].id){
      //   this.$Message.warning('请先创建二级节点')
      //   return;
      // }
      this.curLabelData = data;
      this.labelType = type;
      this.labelSelect = "";
      this.curAddDataSubTabId = "2";
      this.curSelectPoint = {};
      this.curSelectLabel = {};
      // this.selectDataList = data[data.length - 2].datas ? JSON.parse(JSON.stringify(data[data.length - 2].datas)) : [];
      // for(let i = data.length -1;i>=0;i--){
      //   if(data[i].id && data[i].id != -1){
      //     this.selectData = data[i].labels || [];
      //   }
      // }
      this.lastNode = {};
      let index = "";
      for (let i = this.curLabelData.length - 1; i >= 0; i--) {
        if (this.curLabelData[i].id && this.curLabelData[i].id != -1) {
          this.lastNode = this.curLabelData[i].node;
          index = i;
          break;
        }
      }
      if (index === 0 || !index) {
        this.$Message.warning("请先创建二级节点");
        return;
      }
      this.relatedData={
        map_id:this.mapId,
        node_id:this.lastNode.id,
        category_id:this.categoryId
      }
      // this.addLabelsDrawerShow = true;
      this.relatedDrawerShow=true;
      // this.addLabelModal = true;
      // console.log(this.selectData,'this.selectDatathis.selectData')
      // this.curSelectPoint = this.selectData[0];
      // this.curSelectLabel= this.selectDataLable[0];
      this.getNodeDataList();
      this.getDataList();
      this.getLabelList();
    },
    changeSearch(data, name) {
      if (name == "curCategoryId") {
        this.cursecondCategoryId = "-1";
        this.curThirdCategoryId = "-1";
      } else if (name == "cursecondCategoryId") {
        this.curThirdCategoryId = "-1";
      } else if (name == "curStarTypeId") {
        this.starTypeSmall = "";
        this.starTypeBig = "";
        this.searchStarTypeSmall = "";
        this.searchStarTypeBig = "";
      } else if (name == "curRightRateId") {
        this.rightRateSmall = "";
        this.rightRateBig = "";
        this.searchRightRateSmall = "";
        this.searchRightRateBig = "";
      }
      this[name] = data.id;
      this.page = 1;
      this.getCategoryList();
      this.getLabelList();
    },
    getCategoryList() {
      let data = {
        page: 1,
        pageSize: 10,
        category_id: this.curCategoryId,
        category_second_id: this.cursecondCategoryId,
        site_id: -1,
        user_id: -1,
        type: 1,
      };
      if (this.isSuper) {
        data.site_id = "-1";
        data.user_id = "-1";
      }
      this.api.dataset.categoryForm(data).then((res) => {
        //一级标签
        this.categoryList = [
          {
            name: "全部",
            id: "-1",
          },
          ...res.categoryFirstList,
        ];
        //二级标签
        this.secondCategoryList = [
          {
            name: "全部",
            id: "-1",
          },
          ...res.categorySecondList,
        ];
        // console.log(this.secondCategoryList,'this.secondCategoryList')
        this.thirdCategoryList = [
          {
            name: "全部",
            id: "-1",
          },
          ...res.categoryThirdList,
        ];
      });
    },
    changeKeyword(data) {
      this.page = 1;
      this.getLabelList(data);
    },
    changeDataKeyword() {
      this.unRelationDataPage = 1;
      this.getDataList();
    },
    selectAddData(data, row) {
      // console.log(data,'dddd')
      if (row) {
        //单独增加
        this.selectDataList.push(row);
      } else {
        //全选增加
        data.forEach((item) => {
          if (this.selectDataList.length) {
            let len = this.selectDataList.filter((sItem) => {
              return sItem.id == item.id;
            });
            if (!len.length) {
              this.selectDataList.push(item);
            }
          } else {
            this.selectDataList.push(item);
          }
        });
      }
      // this.confirmAddData();
    },
    selectDeleData(data, row) {
      if (row) {
        //单独取消
        let ind = this.selectDataList.findIndex((sItem) => {
          return sItem.id == row.id;
        });
        this.$delete(this.selectDataList, ind);
      } else {
        //全选取消
        this.unRelationDataList.forEach((item) => {
          let ind = this.selectDataList.findIndex((sItem) => {
            return sItem.id == item.id;
          });
          if (ind != -1) {
            this.$delete(this.selectDataList, ind);
          }
        });
      }

      // this.confirmAddData();
    },
    confirmAddData() {
      if (!this.lastNode.id) {
        this.$Message.warning("请选择末级知识点添加标签");
        return;
      }

      let ids = this.selectDataList
        .map((item) => {
          return item.id;
        })
        .join(",");

      let params = {
        map_id: this.mapId,
        node_id: this.lastNode.id,
        data_id: ids,
      };
      if (this.isSuper) {
        params.site_id = "-1";
        params.user_id = "-1";
      }
      this.api.dataset.datasetMapNodeAddData(params).then((res) => {
        this.getNodeDataList();
        this.unRelationDataPage = 1;
        this.getDataList();
        this.selectDataList = [];
      });
    },
    fileSuccess(response, file, fileList) {
      //上传文件成功
      this.uploadFile =
        response.data.info.name + "." + response.data.info.file_extension;
      this.uploadFileId = response.data.info.id;
    },
    confirmUpload() {
      if (!this.uploadFileId) {
        this.$Message.warning("请选择文件～");
        return;
      }
      let params = {
        uploadfile_id: this.uploadFileId,
        map_id: this.mapId,
        // category_id:this.curThirdCategoryId
      };
      if (this.isSuper) {
        params.site_id = "-1";
        params.user_id = "-1";
      }
      this.api.dataset.datasetMapFileImport(params).then((res) => {
        this.$Message.success("上传成功～");
        this.getInfo();
        this.uploadModal = false;
      });
    },
    cancelUpload() {
      this.uploadModal = false;
    },
    leadingIn() {
      this.uploadModal = true;
    },
    changeLabelDataPage(page) {
      this.nodeDataPage = page;
      this.getNodeDataList();
    },
    changeLabelDataPageSize(pageSize) {
      this.nodeDataPage = 1;
      this.nodeDataPageSize = pageSize;
      // console.log(this.labelDataPageSize,'this.labelDataPageSize')
      this.getNodeDataList();
    },
    changeSelectPoint(data) {
      this.curSelectPoint = data;
      this.curSelectLabel = {};
      this.nodeDataPage = 1;
      this.getNodeDataList();
    },
    changeSelectLabel(data) {
      this.curSelectLabel = data;
      this.curSelectPoint = {};
      this.nodeDataPage = 1;
      this.getNodeDataList();
    },
    coverFileSuccess(response, file, fileList) {
      //上传文件成功
      this.bookImg = response.data.info.upload_path;
      // console.log(this.bookImg,'this.coverFile')
      this.coverFileId = response.data.info.id;
    },
    changeAddDataTab(data, name) {
      this[name] = data;
      this.labelSelect = "";

      if (name == "curAddDataSubTabId") {
        this.keyword = "";
        this.page = 1;
        this.labelType = this.curAddDataSubTabId;

        if (this.curAddDataSubTabId == "1") {
          this.curSelectLabel = {};
          this.curSelectPoint = this.selectData.length
            ? this.selectData[0]
            : {};
          this.getLabelList();
        } else if (this.curAddDataSubTabId == "0") {
          this.curSelectPoint = {};
          this.curSelectLabel = this.selectDataLable.length
            ? this.selectDataLable[0]
            : {};
          this.getLabelList();
        } else {
          this.curSelectPoint = {};
          this.curSelectLabel = {};
        }

        this.getNodeDataList();
      }
    },
    getNodeDataList() {
      let params = {
        map_id: this.mapId,
        node_id: this.lastNode.id,
        page: this.nodeDataPage,
        page_size: this.nodeDataPageSize,
      };
      if (this.curSelectPoint.id) {
        params.label_id = this.curSelectPoint.id;
      } else if (this.curSelectLabel.id) {
        params.label_id = this.curSelectLabel.id;
      }

      if (this.isSuper) {
        params.site_id = "-1";
        params.user_id = "-1";
      }
      this.nodeDataLoading = true;
      this.api.dataset
        .datasetMapNodeDataList(params)
        .then((res) => {
          this.nodeDataLoading = false;
          if (this.nodeDataPage > 1 && !res.list.length) {
            this.nodeDataPage = this.nodeDataPage - 1;
            this.getNodeDataList();
          } else {
            this.nodeDataList = res.list;

            this.nodeDataList.forEach((item) => {
              if (item.map_data_selected == "1") {
                item._checked = true;
              }
            });

            this.dataTypes = res.data_types;
            this.nodeDataTotal = Number(res.count);

            this.selectData = res.label_keypoints
              ? JSON.parse(JSON.stringify(res.label_keypoints))
              : [];
            this.selectDataLable = res.label_keywords
              ? JSON.parse(JSON.stringify(res.label_keywords))
              : [];
          }
        })
        .catch((err) => {
          this.nodeDataLoading = false;
        });
    },
    changeDataSearch(dataId) {
      let params = {
        map_id: this.mapId,
        node_id: this.lastNode.id,
        data_id: dataId,
      };

      if (this.isSuper) {
        params.site_id = "-1";
        params.user_id = "-1";
      }
      this.api.dataset.datasetMapNodeAddData(params).then((res) => {
        this.getNodeDataList();
      });
    },
    remoteData(query) {
      if (this.curAddDataSubTabId == "2") {
        this.getDataList(query);
      } else {
        this.changeKeyword(query);
      }
    },
    getDataList() {
      let params = {
        category_id: this.categoryId,
        //map_id:this.mapId,
        keyword: this.dataKeyword,
        page: this.unRelationDataPage,
        pageSize: this.unRelationDataPageSize,
        status: 1,
      };

      if (this.isSuper) {
        params.site_id = "-1";
        params.user_id = "-1";
      }
      this.dataListLoading = true;
      this.api.dataset
        .dataList(params)
        .then((res) => {
          this.dataListLoading = false;
          this.unRelationDataList = res.list;
          this.unRelationDataTotal = Number(res.count);

          this.unRelationDataList.forEach((item) => {
            let len = this.selectDataList.filter((sItem) => {
              return sItem.id == item.id;
            });

            if (len && len.length) {
              item._checked = true;
            } else {
              item._checked = false;
            }
          });
        })
        .catch((err) => {
          this.dataListLoading = false;
        });
    },
    nodeDelData(data, row) {
      let params = {
        map_id: this.mapId,
        node_id: this.lastNode.id,
        data_id: row.data_id,
      };

      if (this.isSuper) {
        params.site_id = "-1";
        params.user_id = "-1";
      }
      this.api.dataset.datasetMapNodeDelData(params).then((res) => {
        this.getNodeDataList();
      });
    },
    nodeAddData(data, row) {
      let params = {
        map_id: this.mapId,
        node_id: this.lastNode.id,
        data_id: row.data_id,
      };

      if (this.isSuper) {
        params.site_id = "-1";
        params.user_id = "-1";
      }
      this.api.dataset.datasetMapNodeAddData(params).then((res) => {
        this.getNodeDataList();
      });
    },
    nodeAddAllData(data) {
      let dataIds = data
        .map((item) => {
          return item.data_id;
        })
        .join(",");
      let params = {
        map_id: this.mapId,
        node_id: this.lastNode.id,
        data_id: dataIds,
      };

      if (this.isSuper) {
        params.site_id = "-1";
        params.user_id = "-1";
      }
      this.api.dataset.datasetMapNodeAddData(params).then((res) => {
        this.getNodeDataList();
      });
    },
    nodeDelAllData() {
      let dataIds = this.nodeDataList
        .map((item) => {
          return item.data_id;
        })
        .join(",");
      let params = {
        map_id: this.mapId,
        node_id: this.lastNode.id,
        data_id: dataIds,
      };

      if (this.isSuper) {
        params.site_id = "-1";
        params.user_id = "-1";
      }
      this.api.dataset.datasetMapNodeDelData(params).then((res) => {
        this.getNodeDataList();
      });
    },
    changeUnRelationDataPage(page) {
      this.unRelationDataPage = page;
      this.getDataList();
    },
    changeUnRelationDataPageSize(pageSize) {
      this.unRelationDataPageSize = pageSize;
      this.unRelationDataPage = 1;
      this.getDataList();
    },
    cancelAddFile() {
      if (this.$refs.uploadimgs) {
        this.$refs.uploadimgs.abort();
      }
      this.addFileModal = false;
    },
    checkImg(data) {
      this.curNodeData = data;
      this.checkImgModal = true;
    },
    // addFile(data, name, type) {
    //   this.addFileType = type;
    //   this.curNodeData = data;
    //   this.videoPath = "";
    //   this.addFileNameList = "";
    //   this.addFileList = [];
    //   this.newFileList = [];
    //   this.curFileIndex = 0;
    //   this.fileLoading = false;
    //   if (data[name].length) {
    //     data[name].forEach((item) => {
    //       if (!item.path.indexOf("http") == 0) {
    //         //不以http开头
    //         item.path = this.constant.URL +
    //           "/uploadfile/show/?file=" +
    //           item.path;
    //       }
    //       this.$set(item, "from", "save"); //是否为已保存文件

    //       this.addFileList.push({
    //         ...item,
    //       });
    //     });
    //   } else {
    //     this.addFileList = [];
    //   }
    // },
    // 预览|绑定 资源
    addFiles(nodeData, curRowIndex) {
      const typeList = ["vedio_info", "ppt_info", "enchiridion_info"];
      this.type = "edit";
      this.tabActive="1";
      this.drawerTitle = `编辑节点-${nodeData.node.name}`;
      this.curNodeData = nodeData;
      this.nodeNum = curRowIndex;
      this.nodeName = this.curNodeData.node.name;
      this.weight = this.curNodeData.weight;
      this.desc = this.curNodeData.desc;
      this.imgUrl = this.curNodeData.img;
      this.sort = this.curNodeData.sort;
      this.nodeId=this.curNodeData.node.id
      if (curRowIndex == 3) {
        this.labels = this.curNodeData.label_ids && this.curNodeData.label_ids.split(',')
        this.getLabelList('', this.labels)
      }
      // 将所有文件添加到列表
      typeList.forEach(type => {
        if (nodeData[type].length) {
          nodeData[type].forEach(item => {
            if (!item.path.indexOf("https") === 0) {
              item.path = this.constant.URL + "/uploadfile/show/?file=" + item.path;
            }
          });
        }
      });
      this.addFileType = "-1";
      this.addExperType="-1"
      // this.addFile(nodeData,"vedio_info","1");

      this.nodeIdSourceList=[];
      this.fileList=[];
      this.getNodeExper();
      this.getNodeSource();
      this.addFileModal = true;
    },
    // 关联实验
    assExper(row){
      this.api.dataset.nodeBindExper({
        map_id:this.mapId,
        node_id:this.curNodeData.node.id,
        type:row.type,
        ids:row.id,
        site_id:"-1"
      }).then(()=>{
        this.getNodeExper();
        this.getAllExperList();
        this.$Message.success("关联实验成功");
      });
    },
    // 解除关联实验
    disassExper(row){
      this.api.dataset.disassExper({
        map_id:this.mapId,
        node_id:this.curNodeData.node.id,
        type:row.type,
        ids:row.id,
        site_id:"-1"
      }).then(()=>{
        this.getNodeExper();
        this.getAllExperList();
        this.$Message.success("解绑成功");
      });
    },
    changeFileName(index) {
      this.curFileIndex = index;
    },
    uploadNodeImgSuccess(response) {
      this.imgUrl = response.data.info.upload_path;
    },
    deleFile(row) {
      // if (this.addFileList[this.curFileIndex].from == "save") {
      //   let id = this.addFileList[this.curFileIndex].id;
      //   let params = {
      //     id: id,
      //   };
      //   if (this.isSuper) {
      //     params.site_id = "-1";
      //     params.user_id = "-1";
      //   }
      //   // this.api.dataset.sourceDelete(params).then((res) => {
      //   //   this.$Message.success("操作成功");
      //   // });
      //   this.api.dataset.nodeDeleteSource({
      //     type: this.addFileList[this.curFileIndex].type,
      //     ids: id,
      //     site_id: "-1",
      //     map_id: this.mapId,
      //     node_id: this.curNodeData.node.id
      //   }).then(() => {
      //     this.$Message.success("解绑成功");
      //   });
      // }
      // let index = this.addFileList.findIndex((item) => {
      //   return item.id == this.addFileList[this.curFileIndex].id;
      // });
      // if (index == 0) {
      //   this.curFileIndex = 0;
      // } else if (index == this.addFileList.length - 1) {
      //   this.curFileIndex--;
      // }
      // this.$delete(this.addFileList, index);
      const { addFileType } = this;
      this.unbindingId = row.id;
      this.unbindingLoading = true;
      this.api.dataset.nodeDeleteSource({
        type: row.type,
        ids: row.id,
        site_id: "-1",
        map_id: this.mapId,
        node_id: this.curNodeData.node.id
      }).then(() => {
        this.changeFileType("", addFileType);
        this.unbindingLoading = false;
        this.$Message.success("解绑成功");
      }).catch(() => {
        this.unbindingLoading = false;
      });
    },
    changeBatchTab(data) {
      this.curBatchTab = data.id;
      this.batchTotal = 0;
      this.batchPage = 1;
      this.batchPageSize = 10;
      this.batchData = [];
      this.batchFileName = "";
      if (this.curBatchTab == 2) {
        this.batchColumns = this.batchPptColumns;
      } else if (this.curBatchTab == 1) {
        this.batchColumns = this.batchVideoColumns;
      } else if (this.curBatchTab == 3) {
        this.batchColumns = this.batchEnchiridionColumns;
      }
    },
    downTemplate() {
      util.downloadFile(
        this,
        "https://public.saas.edu360.cn/1/20231007/1696644939-6520bf4bceb8f/%E8%B5%84%E6%BA%90%E4%B8%8A%E4%BC%A0%E6%A8%A1%E6%9D%BF.xlsx"
      );
    },
    batchFileSuccess(response) {
      this.batchFileName = response.data.info.name;
      this.batchFileId = response.data.info.id;
      let params = {
        uploadfile_id: this.batchFileId,
        map_id: this.mapId,
        type: this.curBatchTab, //1 视频 2 ppt
        category_id: this.categoryId,
      };
      if (this.isSuper) {
        params.site_id = "-1";
        params.user_id = "-1";
      }
      this.api.dataset.sourceImportUploadfile(params).then((res) => {
        this.importBatch = res.import_batch;
        this.getBatchList();
      });
    },
    getBatchList() {
      let params = {
        import_id: this.importBatch,
        page: this.batchPage,
        pageSize: this.batchPageSize,
      };
      if (this.isSuper) {
        params.site_id = "-1";
        params.user_id = "-1";
      }
      this.api.dataset.sourceImportDataList(params).then((res) => {
        if (!res.list.length && page.value > 1) {
          this.batchPage = this.batchPage - 1;
          this.getBatchList();
          return;
        }
        this.batchTotal = Number(res.count);
        this.batchData = res.list;
      });
    },
    changeBatchPage(page) {
      this.batchPage = page;
      this.getBatchList();
    },
    changeBatchPageSize(pageSize) {
      this.batchPage = 1;
      this.batchPageSize = pageSize;
      this.getBatchList();
    },
    batchDele(data) {
      let params = {
        id: data.id,
      };
      if (this.isSuper) {
        params.site_id = "-1";
        params.user_id = "-1";
      }
      this.api.dataset.sourceDelete(params).then((res) => {
        this.$Message.success("操作成功");
        this.getBatchList();
      });
    },
    confirmBatch() {
      let params = {
        import_id: this.importBatch,
      };
      if (this.isSuper) {
        params.site_id = "-1";
        params.user_id = "-1";
      }
      this.api.dataset.sourceImportVerify(params).then((res) => {
        this.$Message.success("操作成功");
        this.batchAddFileModal = false;
        this.getInfo();
      });
    },
    cancelBatchFile() {
      this.batchAddFileModal = false;
    },
    batchUpload() {
      this.batchAddFileModal = true;
      this.batchTotal = 0;
      this.batchPage = 1;
      this.batchPageSize = 10;
      this.batchData = [];
      this.batchFileName = "";
      if (this.curBatchTab == 2) {
        this.batchColumns = this.batchPptColumns;
      } else if (this.curBatchTab == 1) {
        this.batchColumns = this.batchVideoColumns;
      }
    },
    cancelFileModal() {
      this.addAndBind=false;
      if (this.$refs.uploadimgs) {
        this.$refs.uploadimgs.abort();
      }
      this.tabActive = "0";
      this.getInfo();
    },
  },
};
</script>

<style scoped lang="scss">
.map-create {
  margin: 20px;
  padding: 20px;
  background: #ffffff;
  box-shadow: 0px 2px 12px 0px rgba(189, 189, 189, 0.41);
  border-radius: 4px;

  .top {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;

    .left {
      display: flex;
      justify-content: flex-start;
      flex-grow: 1;

      .left-cover {
        margin-right: 30px;
        width: 110px;
        height: 130px;
        border-radius: 6px;

        //overflow: hidden;
        .left-cover-bj {
          background: rgba(0, 0, 0, 0.5);
          width: 110px;
          height: 110px;
          display: flex;
          justify-content: center;
          align-items: center;

          >img {
            width: 18px;
            height: 18px;
          }
        }

        .left-cover-img {
          //width: 64px;
          //height: 90px;
        }
      }

      .left-info {
        display: flex;
        justify-content: flex-start;
        color: #333333;
        font-size: 14px;
        flex-grow: 1;

        .left-info-l {
          margin-right: 60px;

          .left-info-l-item {
            margin-bottom: 6px;
            display: flex;
            justify-content: flex-start;
            align-items: center;

            >span {
              margin-right: 8px;
              font-weight: bold;
              width: 80px;
              text-align: right;
              display: inline-block;
            }

            .left-info-l-item-input {
              width: 200px;
            }
          }
        }

        .bg {
          padding: 20px;
          background-color: #f6f7fa;
          border-radius: 4px;
          flex-grow: 1;
        }
      }

      .cate {
        width: 350px;
      }
    }

    .confirm {
      margin-left: 10px;
    }
  }

  .node-cont {

    //display: flex;
    //justify-content: space-between;
    //align-items: center;
    //min-height: 100px;
    >div {
      display: none;
    }
  }

  .node-cont:hover {
    >div {
      width: 100%;
      //height: 40px;
      display: flex;
      //justify-content: space-between; /* 水平均匀分布 */
      //align-items: center; /* 垂直居中 */
      //flex-direction: column;
      justify-content: space-between;
      align-items: center;

      >p {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
    }
  }
}

.upload-loading {
  height: 178px;
  width: 178px;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-cont {
  .item {
    margin-bottom: 20px;
    display: flex;
    justify-content: flex-start;

    >span {
      width: 100px;
      text-align: right;
      line-height: 30px;
    }

    .val {
      flex: 1;
    }
  }
}

.table {
  width: 100%;
  border: 1px solid #dcdee2;
  font-size: 14px;

  tr {
    height: 40px;
    border-bottom: 1px solid #dcdee2;
    overflow: hidden;

    td,
    th {
      padding: 16px 0;
      text-align: center;
      border-right: 1px solid #dcdee2;
      position: relative;

      .td-name {
        display: flex;
        justify-content: center;
        align-items: center;

        >img {
          margin-left: 5px;
        }
      }

      .td-op {
        margin-top: 10px;
        display: flex;
        justify-content: center;
        align-items: center;

        >div {
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;

          >span {
            padding: 0 5px;
          }

          .has {
            color: #5578f6;
            text-decoration: underline;
            text-underline: #5578f6;
          }
        }
      }
    }

    td,
    th {
      .op-btn {
        display: none;
      }
    }

    td:hover,
    th:hover {
      .op-btn {
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.7);
        color: #ffffff;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        >p {
          cursor: pointer;
        }
      }
    }
  }

  .add-btn {
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    .add-btn-icon {
      display: none;
    }
  }

  .add-btn:hover {
    .add-btn-icon {
      display: block;
    }
  }

  tr:last-child {
    border-bottom: none;
  }

  .label-item {
    margin-right: 5px;
    padding: 0 5px;
    border: 1px solid #2d8cf0;
    height: 38px;
    line-height: 38px;
    border-radius: 4px;
    text-align: center;
    color: #2d8cf0;
  }

  .data-item {
    margin-right: 5px;
    padding: 0 5px;
    border: 1px solid #2d8cf0;
    height: 38px;
    line-height: 38px;
    border-radius: 4px;
    text-align: center;
    color: #2d8cf0;

    >div {
      border-right: none;
      display: inline;
    }
  }

  .table-label {
    height: 40px;

    .add-label-icon {
      display: none;
    }
  }

  .table-label:hover {
    .add-label-icon {
      display: block;
    }
  }
}

.flex_start {
  display: flex;
  justify-content: flex-start;

  >div {
    flex: 1;
    width: 0;
    overflow: hidden;
  }
}

.add-label {
  >div:first-child {
    flex: 1;
  }

  >div:last-child {
    flex: 2;
  }

  .label-data {
    border-left: 1px solid #eeeeee;
    padding-left: 20px;
    margin-left: 20px;
    padding-top: 20px;

    .tit {
      margin-bottom: 20px;
      font-size: 14px;
      font-weight: bold;
    }
  }

  .search {
    margin-top: 20px;
    margin-bottom: 20px;

    .search-item {
      margin-bottom: 15px;
      display: flex;
      justify-content: flex-start;
      font-size: 14px;

      .name {
        padding: 5px 0;
        width: 75px;
        text-align: right;
      }

      .cont {
        margin-left: 15px;
        flex: 1;
        display: flex;
        justify-content: space-between;

        .values {
          display: flex;
          justify-content: flex-start;
          flex-flow: wrap;
          transition: max-height 0.3s linear;
          overflow: hidden;

          >div {
            padding: 5px 10px;
            //margin: 0 10px 10px 5px;
            cursor: pointer;
          }

          >div.active {
            background-color: #2d8cf0;
            color: #ffffff;
            border-radius: 4px;
          }
        }

        .more {
          padding: 5px 0;
          width: 80px;
          text-align: right;
          cursor: pointer;

          .more-icon {
            transition: transform 0.3s linear;
          }
        }
      }
    }
  }

  .select {
    padding: 20px 20px 10px 20px;
    border: 1px solid #dcdee2;

    >p {
      margin-bottom: 10px;
      font-size: 16px;
      font-weight: bold;
    }
  }

  .select-cont {
    margin-top: 20px;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    height: 200px;
    overflow-y: auto;

    >div {
      margin-right: 10px;
      margin-bottom: 10px;
      padding: 0 10px;
      height: 35px;
      border: 1px solid #999999;
      border-radius: 4px;
      text-align: center;
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;

      >p {
        margin-right: 10px;
        color: #666666;
      }
    }

    .active {
      background: #4a79ff;
      border: 1px solid #4a79ff;

      >p {
        color: #ffffff;
      }
    }
  }

  .list-cont {
    margin-top: 20px;
    padding: 20px;
    border: 1px solid #dcdee2;

    .label-search {
      display: flex;
      justify-content: flex-end;
    }

    .keyword-search {
      margin-bottom: 20px;
      width: 300px;
    }
  }

  .list {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;

    >div {
      margin: 0 10px 10px 0;
      padding: 0 10px;
      border: 1px solid #dcdee2;
      height: 40px;
      line-height: 40px;
      border-radius: 4px;
      text-align: center;
      cursor: pointer;
    }

    .active {
      border: 1px solid #2d8cf0;
      color: #2d8cf0;
    }
  }

  .page {
    margin-top: 20px;
    text-align: right;
  }
}

.page {
  margin-top: 20px;
  text-align: right;
}

.add-data-tab {
  margin: 0 0 20px 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
  height: 100%;
  font-size: 16px;
  color: #666666;
  border-bottom: 1px solid #eeeeee;

  >div {
    margin-right: 30px;
    cursor: pointer;
    height: 100%;
    display: flex;
    align-items: center;
    border-bottom: 4px solid transparent;
  }

  .active {
    font-weight: bold;
    color: #4a79ff;
    border-bottom: 4px solid #5782ff;
  }
}

.add-data-sub-tab,
.batch-tab {
  padding-bottom: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid #eeeeee;

  >div {
    margin-right: 10px;
    width: 100px;
    height: 42px;
    background: #f6f7fa;
    border-radius: 6px;
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
    color: #333333;
    text-align: center;
    line-height: 42px;
  }

  .active {
    background: #4a79ff;
    color: #ffffff;
  }
}

.batch-file {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.add-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.name-list {
  margin-top: 20px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;

  >div {
    margin: 0 10px 10px 0;
    padding: 0 10px;
    border: 1px solid #dcdee2;
    height: 40px;
    line-height: 40px;
    border-radius: 4px;
    text-align: center;
    cursor: pointer;
  }

  .active {
    border: 1px solid #2d8cf0;
    color: #2d8cf0;
  }
}

.upload-btn {
  width: 120px;
  height: 120px;
  border: 1px solid #eeeeee;
  display: flex;
  justify-content: center;
  align-items: center;
}

.search {
  margin-top: 20px;
  margin-bottom: 20px;

  .search-item {
    margin-bottom: 15px;
    display: flex;
    justify-content: flex-start;
    font-size: 14px;

    .name {
      padding: 5px 0;
      width: 75px;
      text-align: right;
    }

    .cont {
      margin-left: 15px;
      flex: 1;
      display: flex;
      justify-content: space-between;

      .values {
        display: flex;
        justify-content: flex-start;
        flex-flow: wrap;
        transition: max-height 0.3s linear;
        overflow: hidden;

        >div {
          padding: 5px 10px;
          //margin: 0 10px 10px 5px;
          cursor: pointer;
        }

        >div.active {
          background-color: #2d8cf0;
          color: #ffffff;
          border-radius: 4px;
        }
      }

      .more {
        padding: 5px 0;
        width: 80px;
        text-align: right;
        cursor: pointer;

        .more-icon {
          transition: transform 0.3s linear;
        }
      }
    }
  }
}

.tabbar {
  border-bottom: 1px solid #dcdee2;
  display: flex;
  font-size: 20px;
  font-weight: bold;
  padding-bottom: 20px;
  margin-bottom: 20px;

  >div {
    margin-right: 20px;
    cursor: pointer;
    position: relative;
  }

  .tabActive {
    color: #4a79ff;

    &::after {
      content: "";
      display: block;
      height: 4px;
      width: 100%;
      background-color: #4a79ff;
      position: absolute;
      left: 0;
      bottom: -20px;
    }
  }

  .disable {
    color: #bdc3ca !important;
  }
}

.uploadTypeTab {
  display: flex;
  margin-bottom: 20px;

  .uploadType-item {
    margin-right: 10px;
    font-size: 18px;
    cursor: pointer;
  }

  .uploadType-item-active {
    color: #4a79ff;
  }
}

::v-deep .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

::v-deep .avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

::v-deep .avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>

<style lang="scss" scoped>::v-deep .el-table .success-row {
  background: #f0f9eb;
}

.input-wrap{
  display: flex;
  justify-content: flex-end;
  height: 0;
  .transform{
    width: 200px;
    margin-right: 20px;
    transform: translateY(-50px);
  }
}

.transform35{
  display:flex;
  justify-content: flex-end;
  align-items: center;
  transform:translateY(-35px);
  height:0;
}
</style>
