<template>
  <div>
    <el-drawer :visible.sync="relatedDrawerShow" size="80%" @close="drawerClose()">
      <div class="drawer-title" slot="title">
        <div class="drawer-title-text">添加标签</div>
        <div class="drawer-title-btns"></div>
      </div>
      <div style="padding: 20px;">
        <!-- tab区域 -->
        <div class="tablist">
          <div class="tablist-item" v-show="displayLogic(item.id)" :class="activeTab === item.id ? 'tabActive' : ''" @click="changeTab(item.id)"
            v-for="item in tabList" :key="item.id">{{ item.name }}</div>
        </div>
        <!-- 内容区域 -->
        <template v-if="activeTab === '1'">
          <el-table :data="nodeDataList" border size="small">
            <el-table-column label="ID" width="80">
              <template v-slot="{ row }">
                {{ row.data.id }}
              </template>
            </el-table-column>
            <el-table-column label="类型" width="80">
              <template v-slot="{ row }">
                {{ row?.data?.type ? questionTypeObj[row.data.type] : "" }}
              </template>
            </el-table-column>
            <el-table-column label="题目">
              <template v-slot="{ row }">
                {{ row?.data?.title || "" }}
              </template>
            </el-table-column>
            <el-table-column label="答案">
              <template v-slot="{ row }">
                {{ answerHandle(row.dataOptions) }}
              </template>
            </el-table-column>
            <el-table-column label="考点">
              <template v-slot="{ row }">
                {{ examPointsHandle(row?.data?.labelKeypoints) }}
              </template>
            </el-table-column>
            <el-table-column label="操作" width="160">
              <template v-slot="{ row }">
                <el-button size="small" type="primary" @click="preview(row, '1')">预览</el-button>
                <el-button size="small" type="warning" @click="unbind(row)"
                  :loading="nodeDataLoading && row.id === nodeDataID">解绑</el-button>
              </template>
            </el-table-column>
          </el-table>
          <Page :pagination="bindPagination" @currentChange="currentChange" @sizeChange="sizeChange"
            style="margin-top: 10px;"></Page>
        </template>
        <template v-if="activeTab === '2'">
          <div class="searchArea">
            <Input class="search-input" style="width: 400px;" v-model="allDataPagination.keyword"
              :placeholder="$t('common_search_keyword')" clearable search :enter-button="true"
              @on-search="allDataKeywordChange" />
            <el-tooltip effect="dark" content="多个搜索关键词用逗号隔开" placement="top">
              <i class="el-icon-question icon"></i>
            </el-tooltip>
          </div>
          <el-table :data="allNodeData" border size="small">
            <el-table-column prop="data.id" label="ID" width="80">
            </el-table-column>
            <el-table-column label="类型" width="80">
              <template v-slot="{ row }">
                {{ row.data.type ? questionTypeObj[row.data.type] : "" }}
              </template>
            </el-table-column>
            <el-table-column label="题目">
              <template v-slot="{ row }">
                {{ row.data.title || "" }}
              </template>
            </el-table-column>
            <el-table-column label="匹配度" prop="score" width="100"></el-table-column>
            <el-table-column label="操作" width="160">
              <template v-slot="{ row }">
                <el-button size="small" type="primary" @click="preview(row.data)">预览</el-button>
                <span v-if="row.isBind" style="margin-left: 10px;">已绑定</span>
                <el-button v-else size="small" @click="aiDataUnbind(row.data)"
                  :loading="aiDataLoading && row.data.id === aiDataId">绑定</el-button>
              </template>
            </el-table-column>
          </el-table>
          <Page :pagination="allDataPagination" @currentChange="allDataCurrentChange" @sizeChange="allDataSizeChange"
            style="margin-top: 10px;"></Page>
        </template>
        <template v-if="activeTab === '3'">
          <Input style="margin-bottom: 20px;width: 400px;" v-model="currentDataPagination.keyword"
            :placeholder="$t('common_search_keyword')" clearable search :enter-button="true"
            @on-search="currentDataKeywordChange" />
          <el-table :data="currentNodeDataList" border size="small">
            <el-table-column prop="id" label="ID" width="80">
            </el-table-column>
            <el-table-column prop="type" label="类型" width="80">
              <template v-slot="{ row }">
                {{ row.type ? questionTypeObj[row.type] : "" }}
              </template>
            </el-table-column>
            <el-table-column prop="title" label="题目">
            </el-table-column>
            <el-table-column label="操作" width="160">
              <template v-slot="{ row }">
                <el-button size="small" type="primary" @click="preview(row)">预览</el-button>
                <span v-if="row.isBind" style="margin-left: 10px;">已绑定</span>
                <el-button v-else size="small" @click="currentDataBind(row)"
                  :loading="currentDataLoading && row.id === currentDataId">绑定</el-button>
              </template>
            </el-table-column>
          </el-table>
          <Page :pagination="currentDataPagination" @currentChange="currentDataCurrentChange"
            @sizeChange="currentDataSizeChange" style="margin-top: 10px;"></Page>
        </template>
      </div>
    </el-drawer>
    <el-drawer :visible.sync="previewDrawerShow" size="80%">
      <div class="drawer-title" slot="title">
        <div class="drawer-title-text">预览试题</div>
      </div>
      <div style="padding: 20px;">
        <el-form ref="form" :model="previewData" label-width="90px">
          <el-form-item label="ID：">
            <div class="preview-item">{{ previewData.id }}</div>
          </el-form-item>
          <el-form-item label="分类：">
            <div class="preview-item" v-show="previewData.categoryList">{{
              previewCategoryData(previewData.categoryList)
            }}</div>
          </el-form-item>
          <el-form-item label="状态：">
            <div class="preview-item" v-show="previewData.status">{{ statusObj[previewData.status] }}</div>
          </el-form-item>
          <el-form-item label="难度：">
            <div class="preview-item">{{ questionLevle[previewData.level] }}</div>
          </el-form-item>
          <el-form-item label="关键词：">
            <div class="preview-item" v-show="previewData.labelKeywords">
              {{ 
                previewKeywords(previewData.labelKeywords)
              }}
            </div>
          </el-form-item>
          <el-form-item label="考点：">
            <div class="preview-item" v-show="previewData?.labelKeypoints?.length">{{ previewData?.labelKeypoints?.map(item=>item.label.name)?.join("，") }}</div>
          </el-form-item>
          <el-form-item label="考试来源：">
            <div class="preview-item" v-show="previewData.source">{{ sourceFroms[previewData.source] }}</div>
          </el-form-item>
          <el-form-item label="题型：">
            <div class="preview-item">{{ questionTypeObj[previewData.type] }}</div>
          </el-form-item>
          <el-form-item label="标题：">
            <div class="preview-item">{{ previewData.title }}</div>
          </el-form-item>
          <el-form-item label="答案：" key="input" v-if="Number(previewData.type)>3">
            <div class="preview-item" v-show="previewData?.options?.length">{{ previewData?.options?.map(item=>item.name)?.join("，") }}</div>
          </el-form-item>
          <el-form-item label="答案：" key="select" v-else>
            <div class="preview-item">
              <div class="item-select" v-for="(item,index) in previewData.options" :key="index">
                <div class="yes" v-if="item.is_correct==='1'"></div>
                <div class="no" v-else></div>
                <div>{{ String.fromCharCode(index+65)+"、"+item.name}}</div>
              </div>
              <div>正确选项：{{ previewData.rightkey }}</div>
            </div>
          </el-form-item>
          <el-form-item label="解析：">
            <div class="preview-item" v-html="previewData.explain"></div>
          </el-form-item>
        </el-form>
      </div>
    </el-drawer>
  </div>
</template>

<script setup>
import { ref, reactive, watch, getCurrentInstance } from 'vue';
import Page from "@/components/myPagin.vue";

// this实例
const instance = getCurrentInstance();

// 定义props
const props = defineProps({
  drawerShow: {
    type: Boolean,
    default: false
  },
  relatedData: {
    type: Object,
    required: true
  },
  isSuper: {
    type: Boolean,
    default: false
  },
  curSelectPoint: {
    type: Object,
    required: true
  },
  curSelectLabel: {
    type: Object,
    required: true
  }
});
// 定义emit
const emit = defineEmits(["drawerClose"]);


// 抽屉可见性
const relatedDrawerShow = ref(false);
// 抽屉确定按钮
const submit = () => {

}
// 抽屉关闭
const drawerClose = () => {
  activeTab.value = "1";
  relatedDrawerShow.value = false;
  bindPagination.page=1;
  allDataPagination.page=1;
  currentDataPagination.page=1;
  allDataPagination.keyword="";
  emit("drawerClose");
}

// 绑定tab的分页器数据
const bindPagination = reactive({
  page: 1,
  pageSize: 10,
  total: 0
});
// 绑定tab的分页器当前页改变
const currentChange = (page) => {
  bindPagination.page = page;
  getNodeDataList();
}
// 绑定tab的分页器当前条数改变
const sizeChange = (size) => {
  bindPagination.pageSize = size;
  getNodeDataList();
}
// tab显示逻辑
const displayLogic=(id)=>{
  if(props.isSuper)return true;
  // 不是超管不显示全部题库
  if(id==="2"){
    return false;
  }else{
    return true;
  }
}

// 获取所有数据
const getAllTabDataList = () => {
  getNodeDataList();
  aiMatchNodeData();
  currentNodeData();
}

// 答案处理
const answerHandle = (list) => {
  if (!list) return;
  const arr = list.filter(item => {
    return item.is_correct === "1";
  });
  let str = "";
  arr.forEach(item => {
    str += item.name;
  });
  return str;
}
// 处理考点
const examPointsHandle = (list) => {
  let str = "";
  list?.forEach((item) => {
    str += item.label.name;
  });
  return str;
}

// 试题类型数据
const questionTypeObj = ref({});
// 试题难度数据
const questionLevle = ref({});
// 已绑定的试题数据
const nodeDataList = ref([]);
// 已绑定试题的id列表
const nodeIdDataList=ref([]);
// 获取已绑定的试题数据
const getNodeDataList = () => {
  const params = {
    map_id: props.relatedData.map_id,
    node_id: props.relatedData.node_id,
    page: bindPagination.page,
    page_size: bindPagination.pageSize,
  }
  if (props.curSelectPoint.id) {
    params.label_id = props.curSelectPoint.id;
  } else if (props.curSelectLabel.id) {
    params.label_id = props.curSelectLabel.id;
  }
  if (props.isSuper) {
    params.site_id = "-1";
    params.user_id = "-1";
  }
  instance.proxy.api.dataset.datasetMapNodeDataList(params).then((res) => {
    questionLevle.value = res.data_levels;
    bindPagination.total = Number(res.count);
    questionTypeObj.value = res.data_types;
    const arr=[];
    Object.keys(res.node_data_ids).forEach(key=>{
      arr.push(res.node_data_ids[key]);
    });
    nodeIdDataList.value=arr;
    nodeDataList.value = res.list || [];
  });
}
// 按钮加载状态相关
const nodeDataLoading = ref(false);
const nodeDataID = ref("");
// 已绑定知识点解绑
const unbind = (row) => {
  nodeDataID.value = row.id;
  nodeDataLoading.value = true;
  const params = {
    map_id: props.relatedData.map_id,
    node_id: props.relatedData.node_id,
    data_id: row.data_id,
  };
  if (props.isSuper) {
    params.site_id = "-1";
    params.user_id = "-1";
  }
  instance.proxy.api.dataset.datasetMapNodeDelData(params).then(() => {
    getAllTabDataList();
    nodeDataLoading.value = false;
  });
}

// 全部题库试题分页器数据
const allDataPagination = reactive({
  page: 1,
  pageSize: 10,
  total: 0,
  keyword: ""
});
// 当前页改变
const allDataCurrentChange = (page) => {
  allDataPagination.page = page;
  aiMatchNodeData();
}
// 每页条数改变
const allDataSizeChange = (size) => {
  allDataPagination.pageSize = size;
  aiMatchNodeData();
}
// 关键字改变
const allDataKeywordChange = () => {
  allDataPagination.page = 1;
  aiMatchNodeData();
}
// 全部题库试题数据
const allNodeData = ref([]);
// 获取全部试题数据
const aiMatchNodeData = () => {
  instance.proxy.api.dataset.aiMatchNodeData({
    map_id: props.relatedData.map_id,
    node_id: props.relatedData.node_id,
    page: allDataPagination.page,
    page_size: allDataPagination.pageSize,
    keyword: allDataPagination.keyword
  }).then(res => {
    res.list.forEach(item=>{
      item.isBind=false;
    });
    allNodeData.value = res.list || [];
    allDataPagination.total = Number(res.total);
    allDataPagination.keyword = res.keyword || "";
  });
}
const aiDataLoading = ref(false);
const aiDataId = ref("");
// 全部下的题库试题的绑定
const aiDataUnbind = (row) => {
  aiDataId.value = row.id;
  aiDataLoading.value = true;
  const params = {
    map_id: props.relatedData.map_id,
    node_id: props.relatedData.node_id,
    data_id: row.id,
  };
  if (props.isSuper) {
    params.site_id = "-1";
    params.user_id = "-1";
  }
  instance.proxy.api.dataset.datasetMapNodeAddData(params).then(() => {
    // allDataPagination.page = 1;
    getAllTabDataList();
    aiDataLoading.value = false;
  });
}

// 试题状态数据
const statusObj = ref({});
// 当前题库数据分页器数据
const currentDataPagination = reactive({
  page: 1,
  pageSize: 10,
  total: 0,
  keyword: ""
});
// 当前页改版
const currentDataCurrentChange = (page) => {
  currentDataPagination.page = page;
  currentNodeData();
}
// 每页条数改变
const currentDataSizeChange = (size) => {
  currentDataSizeChange.pageSize = size;
  currentNodeData();
}
// 关键字改变
const currentDataKeywordChange = () => {
  currentDataPagination.page = 1;
  currentNodeData();
}
// 当前题库试题数据
const currentNodeDataList = ref([]);
// 获取当前题库试题数据
const currentNodeData = () => {
  const params = {
    category_id: props.relatedData.category_id,
    keyword: currentDataPagination.keyword,
    page: currentDataPagination.page,
    pageSize: currentDataPagination.pageSize,
    status: 1,
  };
  if (props.isSuper) {
    params.site_id = "-1";
    params.user_id = "-1";
  }
  instance.proxy.api.dataset.dataList(params).then((res) => {
    res.list.forEach(item=>{
      item.isBind=false;
    });
    console.log("本题库试题",res);
    currentNodeDataList.value = res.list || [];
    currentDataPagination.total = Number(res.count);
    statusObj.value = res.statuses;
  });
}
// 绑定和已绑定状态监听
watch([nodeIdDataList, currentNodeDataList, allNodeData], () => {
  // 本题库
  currentNodeDataList.value.forEach(item => {
    if (nodeIdDataList.value.find(item2 => item2 === item.id)) {
      item.isBind = true;
    } else {
      item.isBind = false;
    }
  });
  // 全部题库
  allNodeData.value.forEach(item => {
    if (nodeIdDataList.value.find(item2 => item2 === item.data.id)) {
      item.isBind = true;
    } else {
      item.isBind = false;
    }
  });
},{immediate:true});
// 按钮加载状态
const currentDataLoading = ref(false);
const currentDataId = ref("");
// 当前题库试题绑定
const currentDataBind = (row) => {
  currentDataId.value = row.id;
  currentDataLoading.value = true;
  const params = {
    map_id: props.relatedData.map_id,
    node_id: props.relatedData.node_id,
    data_id: row.id,
  };
  if (props.isSuper) {
    params.site_id = "-1";
    params.user_id = "-1";
  }
  instance.proxy.api.dataset.datasetMapNodeAddData(params).then(() => {
    // currentDataPagination.page = 1;
    getAllTabDataList();
    currentDataLoading.value = false;
  });
}

// 监听抽屉打开关闭
watch(() => props.drawerShow, (newVal) => {
  relatedDrawerShow.value = newVal;
  if (newVal) {
    getNodeDataList();
    aiMatchNodeData();
    currentNodeData();
  }
}, { immediate: true });

// 当前激活的tab
const activeTab = ref("1");
// 更改tab
const changeTab = (id) => {
  activeTab.value = id;
}
// tab数据
const tabList = [
  {
    id: "1",
    name: "已绑定试题"
  },
  {
    id: "2",
    name: "全部题库试题"
  },
  {
    id: "3",
    name: "本题库试题"
  }
];

// 试题来源
const sourceFroms=ref({});
// 预览抽屉可见性
const previewDrawerShow = ref(false);
// 预览数据
const previewData = ref({});
// 预览试题
const preview = (row, type) => {
  let id = "";
  if (type === "1") {
    id = row.data.id;
  } else {
    id = row.id;
  }
  instance.proxy.api.dataset.dataDetail({
    id
  }).then((res) => {
    sourceFroms.value=res.source_froms || {};
    previewData.value = res.info || {};
  });
  previewDrawerShow.value = true;
}

// 分类预览
const previewCategoryData = (categoryObj) => {
  if (!categoryObj) return;
  let strs = "";
  Object.keys(categoryObj).forEach(key => {
    let str = categoryObj[key].reduce((pre, cur) => {
      pre += cur.name + "-";
      return pre;
    }, "");
    str = str.slice(0, str.length - 1);
    strs += str + "，";
  });
  strs = strs.slice(0, strs.length - 1);
  return strs;
}
// 关键词预览
const previewKeywords=(keywordList)=>{
  if(!keywordList) return;
  return keywordList.reduce((pre,cur)=>{
    pre.push(cur.label.name);
    return pre;
  },[]).join("，");
}

</script>

<style lang="scss" scoped>
.tablist {
  display: flex;
  border-bottom: 1px solid #dcdee2;
  display: flex;
  font-size: 20px;
  font-weight: bold;
  padding-bottom: 20px;
  margin-bottom: 20px;

  .tablist-item {
    margin-right: 20px;
    cursor: pointer;
    position: relative;
  }
}

.tabActive {
  color: #4a79ff;

  &::after {
    content: "";
    display: block;
    height: 4px;
    width: 100%;
    background-color: #4a79ff;
    position: absolute;
    left: 0;
    bottom: -20px;
  }
}

.searchArea {
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  .search-input {
    margin-right: 5px;
  }

  .icon {
    font-size: 16px;
    margin-top: 4px;
  }
}

.preview-item {
  border-radius: 4px;
  border: 1px solid rgb(217, 217, 217);
  padding: 0 20px;
  .item-select{
    display: flex;
    align-items: center;
    .yes,.no{
      height: 15px;
      width: 15px;
      border-radius: 50%;
      border:1px solid rgb(98, 98, 98);
      margin-right: 10px;
    }
    .yes{
      border: none;
      background-color: gray;
    }
  }
}
</style>