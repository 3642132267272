<template>
  <el-drawer :visible.sync="drawerShow" size="80%" @close="drawerClose()">
      <div class="drawer-title" slot="title">
        <div class="drawer-title-text">添加实验</div>
        <div class="drawer-title-btns"></div>
      </div>
      <div style="padding: 20px;">
        <!-- <div class="tabbar">
          <div class="tabActive">基本信息</div>
          <div class="disable">已绑定资源</div>
          <div class="disable">全部资源</div>
        </div> -->
        <el-form ref="formRef" :model="addEditForm" :rules="rules" size="small" label-width="80px">
          <el-form-item label="名称" prop="name">
            <el-input v-model="addEditForm.name" @focus="clearValidate('name')" placeholder="请输入实验名称"
              clearable></el-input>
          </el-form-item>
          <el-form-item label="认证脚本" prop="auth_script">
            <el-select v-model="addEditForm.auth_script" @focus="clearValidate('auth_script')" placeholder="请选择认证脚本" clearable style="width: 100%;">
              <el-option v-for="item in authScriptList" :key="item.id" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="类型" prop="type">
            <el-select v-model="addEditForm.type" @focus="clearValidate('type')" placeholder="请选择实验类型" clearable style="width: 100%;">
              <el-option v-for="item in typeList" :key="item.id" :label="item.name" :value="item.id"
                :disabled="item.id === '-1'">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="描述">
            <el-input v-model="addEditForm.desc" type="textarea" placeholder="请输入实验描述"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" size="small" @click="drawerSubmit()">确定</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-drawer>
</template>

<script>
  export default {
    name:"AddExper",
    props:{
      show:{
        type:Boolean,
        default:false
      },
      drawerData:{
        type:Object,
        default:()=>{
          return {
            type:"0"
          }
        }
      }
    },
    data(){
      return{
        drawerShow:false,
        // 创建实验表单
        addEditForm: {
          id: "",
          name: "",
          type: "",
          desc: "",
          auth_script: "",
          parent_id: ""
        },
        // 表单验证规则
        rules: {
          name: [
            { required: true, message: "请输入实验名称", trigger: "blur" }
          ],
          auth_script: [
            { required: true, message: "请选择认证脚本", trigger: "change" }
          ],
          type: [
            { required: true, message: "请选择实验类型", trigger: "change" }
          ]
        },
        typeList:[],
        authScriptList:[]
      }
    },
    methods:{
      getTableList() {
        this.api.dataset.getExperimentList({
          site_id: "-1",
          user_id: "-1",
        }).then(res => {
          const arr = [];
          Object.keys(res.types).forEach(key => {
            arr.push({
              id: key,
              name: res.types[key]
            });
          });
          this.typeList = arr;

          const arr1 = [];
          Object.keys(res.auth_scripts).forEach(key => {
            arr1.push({
              id: key,
              name: res.auth_scripts[key]
            });
          });
          this.authScriptList = arr1;
        });
      },
      // 提交
      drawerSubmit() {
        const { addEditForm } = this;
        this.$refs.formRef.validate(valid => {
          if (valid) {
            this.api.dataset.createExperiment(addEditForm).then(() => {
              this.drawerClose();
              this.$Message.success("添加成功");
            });
          }
        });
      },
      // 清除某个字段的验证
      clearValidate(key) {
        this.$refs.formRef.clearValidate(key);
      },
      // 抽屉关闭
      drawerClose() {
        this.addEditForm = {
          id: "",
          name: "",
          type: "",
          desc: "",
          parent_id: "",
          auth_script: ""
        }
        this.$emit("close");
        this.$refs?.formRef?.resetFields();
      },
    },
    watch:{
      show(newVal){
        if(newVal){
          this.addEditForm.type=this.drawerData.type=="-1"?'':this.drawerData.type;
          this.getTableList();
        }
        this.drawerShow=newVal;
      }
    }
  }
</script>

<style lang="scss" scoped>
.tabbar {
    border-bottom: 1px solid #dcdee2;
    display: flex;
    font-size: 20px;
    font-weight: bold;
    padding-bottom: 20px;
    margin-bottom: 20px;

    >div {
      margin-right: 20px;
      cursor: pointer;
      position: relative;
    }

    .tabActive {
      color: #4a79ff;

      &::after {
        content: "";
        display: block;
        height: 4px;
        width: 100%;
        background-color: #4a79ff;
        position: absolute;
        left: 0;
        bottom: -20px;
      }
    }

    .disable {
      color: #bdc3ca;
    }
  }
</style>